import {Injectable} from '@angular/core';
import {AccountRestService} from '../../admin/profile/services/account.rest.service';
import {UserService} from './user/user.service';
import {TranslateService} from '@ngx-translate/core';
import {DmsDataService} from './dms-data.service';
import {CarService} from './car/car.service';
import {CustomerDataService} from './customerData.service';
import {CartService} from '../../cart/services/cart.service';
import {IFirm} from '../interfaces/DTO/firm/firm';

@Injectable()
export class PdfService {

    public firmData: IFirm;
    public client: any = {};

    constructor(public userService: UserService,
                private translate: TranslateService,
                private dmsDataService: DmsDataService,
                private carService: CarService,
                private accountRestService: AccountRestService,
                private customerDataService: CustomerDataService,
                private cartService: CartService) {
        this.userService.accountData$.subscribe((step) => {
            if(step) {
                this.startupCall();
            }
        });
    }


    public startupCall() {
        this.accountRestService.getFirmData().subscribe(
            (response) => {
                this.firmData = response;
            });
        this.client = {};
        this.cartService.onCartChange.subscribe(() => {
            this.client = {};
        });
    }

    public updateDmsData() {
        if (this.dmsDataService && this.dmsDataService.dmsData) {
            if (this.customerDataService.getCustomerFirstName() || this.customerDataService.getCustomerLastName()) {
                this.client.name = this.customerDataService.getCustomerFirstName() + ' ' + this.customerDataService.getCustomerLastName();
            } else {
                this.client.name = '';
            }

            if (this.customerDataService.getCustomerAddress()) {
                this.client.street = this.customerDataService.getCustomerAddress();
            } else {
                this.client.street = '';
            }

            if (this.customerDataService.getCustomerZip()) {
                this.client.zipCode = this.customerDataService.getCustomerZip();
            } else {
                this.client.zipCode = '';
            }

            if (this.customerDataService.getCustomerCity()) {
                this.client.city = this.customerDataService.getCustomerCity();
            } else {
                this.client.city = '';
            }

            if (this.customerDataService.getNumberplate()) {
                this.client.carNumberPlate = this.customerDataService.getNumberplate();
            } else {
                this.client.carNumberPlate = '';
            }

            if (this.customerDataService.getMileage()) {
                this.client.mileage = this.customerDataService.getMileage();
            } else {
                this.client.mileage = '';
            }

            if (this.carService.selectedCar && this.carService.selectedCar.displayName) {
                this.client.carName = this.carService.selectedCar.displayName;
            } else {
                this.client.carName = '';
            }
        }
    }

    public buildDocDefinition(address: boolean = false, car: any, order: boolean = false, tyre: boolean = false) {
        const me = this;
        const doc = {
            content: [],
            header: function (page: any, pages: any) {
                return [
                    {
                        text: me.translate.instant('PRINT_VIEW.PAGE') + ': ' + page.toString() + ' / ' + pages,
                        fontSize: 7,
                        alignment: 'right',
                        margin: [40, 20]
                    }
                ]
            },
            footer: [],
            pageMargins: [57, 40, 40, 120],
            pageBreakBefore: function (currentNode: any, followingNodesOnPage: any, nodesOnNextPage: any, previousNodesOnPage: any) {
                return currentNode.startPosition.top >= 665;
            },
            styles: {
                header: {
                    bold: true,
                    color: '#000',
                    fontSize: 9
                },
                centeredHeader: {
                    bold: true,
                    color: '#000',
                    fontSize: 9,
                    alignment: 'center'
                },
                rightHeader: {
                    bold: true,
                    color: '#000',
                    fontSize: 9,
                    alignment: 'right'
                },
                cartTable: {
                    color: '#000',
                    fontSize: 10,
                    alignment: 'justify'
                },
                pricesTable: {
                    color: '#000',
                    fontSize: 10,
                    margin: [267, 0, 0, 0]
                },
                headerTable: {
                    color: '#000',
                    fontSize: 10,
                    margin: [345, 0, 0, 40]
                },
                addressLine: {
                    fontSize: 10,
                    margin: [0, 0, 0, 0]
                },
                addressLineSmall: {
                    color: '#000',
                    fontSize: 8,
                    decoration: 'underline',
                    margin: [0, 87, 0, 5]
                },
                carData: {
                    fontSize: 9,
                    width: '100%',
                    color: '#000',
                    margin: [0, 5, 0, 10]
                },
                footer: {
                    fontSize: 9,
                    width: '100%',
                    color: '#000',
                    alignment: 'left',
                    margin: [0, 5, 0, 10]
                },
                logo:
                    {
                        alignment: 'right'
                    }
            }
        };

        if (order) {
            let address_data = {};
            if (this.userService.getFirmLogo() && this.userService.getFirmLogo() !== '') {
                address_data = {
                    alignment: 'justify',
                    columns: [
                        [
                            {
                                text: '',
                                style: 'addressLineSmall',
                            },
                            {
                                text: '',
                                style: 'addressLine'
                            },
                            {
                                text: '',
                                style: 'addressLine'
                            },
                            {
                                text: '',
                                style: 'addressLine'
                            },
                            {
                                text: '',
                                style: 'addressLine'
                            }
                        ],
                        {
                            image: this.userService.getFirmLogo() ? this.userService.getFirmLogo() : null,
                            style: 'logo',
                            width: 150
                        }
                    ]
                };

            }
            doc.content.push(address_data);
        }

        if (address) {
            let address_data = {};
            if (this.userService.getFirmLogo() && this.userService.getFirmLogo() !== '') {
                address_data = {
                        alignment: 'justify',
                        columns: [
                            [
                                {
                                    text: (this.firmData ? this.firmData.name + ', ' + this.firmData.address.address1 + ', '
                                        + this.firmData.address.zip + ', ' + this.firmData.address.city : ''),
                                    style: 'addressLineSmall'
                                },
                                {
                                    text: (this.customerDataService.getCustomerFirstName()
                                        ? this.customerDataService.getCustomerFirstName() : '') + ' '
                                        + (this.customerDataService.getCustomerLastName()
                                            ? this.customerDataService.getCustomerLastName() : ''),
                                    style: 'addressLine'
                                },
                                {
                                    text: (this.customerDataService.getCustomerAddress()
                                        ? this.customerDataService.getCustomerAddress() : ''),
                                    style: 'addressLine'
                                },
                                {
                                    text: (this.customerDataService.getCustomerZip() ? this.customerDataService.getCustomerZip() : '')
                                        + ' ' + (this.customerDataService.getCustomerCity()
                                            ? this.customerDataService.getCustomerCity() : ''),
                                    style: 'addressLine'
                                }
                            ],
                            {
                                image: this.userService.getFirmLogo() ? this.userService.getFirmLogo() : null,
                                style: 'logo',
                                width: 150
                            }
                        ]
                    };
            } else {
                // address box
                address_data = {
                        alignment: 'justify',
                        columns: [
                            [
                                {
                                    text: (this.firmData ? this.firmData.name + ', ' + this.firmData.address.address1 + ', '
                                        + this.firmData.address.zip + ', ' + this.firmData.address.city : ''),
                                    style: 'addressLineSmall'
                                },
                                {
                                    text: (this.customerDataService.getCustomerFirstName() ? this.customerDataService.getCustomerFirstName()
                                        : '') + ' ' + (this.customerDataService.getCustomerLastName()
                                        ? this.customerDataService.getCustomerLastName() : ''),
                                    style: 'addressLine'
                                },
                                {
                                    text: (this.customerDataService.getCustomerAddress()
                                        ? this.customerDataService.getCustomerAddress() : ''),
                                    style: 'addressLine'
                                },
                                {
                                    text: (this.customerDataService.getCustomerZip()
                                        ? this.customerDataService.getCustomerZip() : ''),
                                    style: 'addressLine'
                                }
                            ]
                        ]
                    };
            }
            doc.content.push(address_data);
        } else {

            if (car && !tyre) {

                if (!car.constructionTo || car.constructionTo.trim().length === 0) {
                    car.constructionTo = this.translate.instant('SPAREPARTS_VIEW.CAR_DETAILS.TODAY');
                }

                car.displayName = car.carProducerName + ' ' + car.carModelName + ' ' + car.carTypeName + ' (' + car.carTypePowerKW + 'KW/'
                    + car.carTypePowerPS + 'PS) ' + car.constructionFrom + ' - ' + car.constructionTo;
                if (this.userService.getFirmLogo() && this.userService.getFirmLogo() !== '') {
                    const address_data = [
                        {
                            image: this.userService.getFirmLogo(),

                            width: 150,
                            margin: [370, 0, 0, 10]
                        },
                        {
                            text: me.translate.instant('PRINT_VIEW.CAR') + ' ',
                            margin: [0, 1, 0, 0],
                            fontSize: 12
                        },
                        {
                            text: car.displayName,
                            margin: [0, 10, 0, 10],
                            fontSize: 14
                        },
                        {
                            text: me.translate.instant('PRINT_VIEW.MOTORCODE') + ': ' + car.engineCodes.join(','),
                            margin: [0, 10, 0, 10],
                            fontSize: 12
                        }
                    ];


                    doc.content.push(address_data);
                } else {
                    const address_data = [

                        {
                            text: me.translate.instant('PRINT_VIEW.CAR') + ' ',
                            margin: [0, 1, 0, 0],
                            fontSize: 12
                        },
                        {
                            text: car.displayName,
                            margin: [0, 10, 0, 10],
                            fontSize: 14
                        },
                        {
                            text: me.translate.instant('PRINT_VIEW.MOTORCODE') + ': ' + car.engineCodes.join(','),
                            margin: [0, 10, 0, 10],
                            fontSize: 12
                        }
                    ];


                    doc.content.push(address_data);
                }

            }

            if (tyre) {
                if (!car.constructionTo || car.constructionTo.trim().length === 0) {
                    car.constructionTo = this.translate.instant('SPAREPARTS_VIEW.CAR_DETAILS.TODAY');
                }
                car.displayName = car.carProducerName + ' ' + car.carModelName + ' ' + car.carTypeName + ' (' + car.carTypePowerKW + 'KW/'
                    + car.carTypePowerPS + 'PS) ' + car.constructionFrom + ' - ' + car.constructionTo;
                if (this.userService.getFirmLogo() && this.userService.getFirmLogo() !== '') {
                    const address_data = [
                        {
                            image: this.userService.getFirmLogo(),

                            width: 150,
                            margin: [370, 0, 0, 10]
                        },
                        {
                            text: me.translate.instant('PRINT_VIEW.TYRE') + ' ',
                            margin: [0, 1, 0, 0],
                            fontSize: 12
                        },
                        {
                            text: car.displayName,
                            margin: [0, 10, 0, 10],
                            fontSize: 14
                        },
                    ];


                    doc.content.push(address_data);
                } else {
                    const address_data = [

                        {
                            text: me.translate.instant('PRINT_VIEW.TYRE') + ' ',
                            margin: [0, 1, 0, 0],
                            fontSize: 12
                        },
                        {
                            text: car.displayName,
                            margin: [0, 10, 0, 10],
                            fontSize: 14
                        },
                    ];


                    doc.content.push(address_data);
                }
            }
        }
        return doc;
    }

    public printSpecs() {

    }
}
