import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Rx';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from './user/user.service';
import {CarService} from './car/car.service';
import {Title} from '@angular/platform-browser';

declare let document: any;

@Injectable()
export class ThemeService {
    public currentTheme: string;
    public loading: any;
    public auth: any = undefined;
    public language: string;

    constructor(private translate: TranslateService,
                private http: HttpClient,
                private userService: UserService,
                private carService: CarService,
                private titleService: Title) {
        this.loading = true;
        this.getThemeDetails().subscribe(
            (success) => {
                if (!this.userService.accountData) {
                    this.changeTheme(success.theme);
                }
                this.auth = success;
                this.loading = false;
                this.userService.authMethode$.next(success.authMethod);
            }
        );

        this.carService.selectedCarChanged$.subscribe((car) => {
            if (car && car.displayName) {
                this.titleService.setTitle('N4Parts ' + ' | ' + car.displayName);
            } else {
                this.titleService.setTitle('N4Parts');
            }
        });

        this.userService.accountData$.subscribe(
            (step) => {
                if (step) {
                    this.changeTheme(step.theme);
                }
            }
        );
    }

    public changeTheme(themeName?: string) {
        if (themeName) {
            document.body.className = themeName;
            this.currentTheme = themeName;
        }
    }

    public changeLanguage(language?: string) {
        if (language) {
            this.translate.use(language);
            this.language = language;
        }
    }

    private getTitle(themeName: string) {
        switch (themeName) {
            case 'GDRP-THEME':
                return 'GDRP';

            case 'ANAG-THEME':
                return 'Anag';

            case 'GDHS-THEME':
                return 'GDHS';

            case 'DRIVER-THEME':
                return 'Driver';

            case 'INCADEA-THEME':
                return 'Incadea';

            case 'POINTS-THEME':
                return 'Point-S';

            case 'AM-THEME':
                return 'Automeister';

            case 'EUROMASTER-THEME':
                return 'Euromaster';

            // case 'TYRE1-THEME':
            //     return 'tyre1 - KFZ Teileplattform';

            case 'PREMIO_PL-THEME':
                return 'Premio';

            default:
                return 'N4Parts';
        }
    }

    public getThemeDetails(): Observable<any> {
        return this.http.get(
            `/external/theme`
        );
    }

    private performRedirectIfPossible() {
        if ((this.auth.authMethod === "SAML" || this.auth.authMethod === "CAS" || this.auth.authMethod === "N4SSO" || this.auth.authMethod === "EMSSSO") && this.auth.loginUrl !== null) {
            document.location.href = this.auth.loginUrl;
        }
    }

    public customLogin() {
        if (!this.auth) {
            this.getThemeDetails().subscribe(
                (theme) => {
                    this.auth = theme;
                    this.performRedirectIfPossible();
                }
            )
        } else {
            this.performRedirectIfPossible();
        }
    }
}
