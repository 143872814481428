import {Component, OnInit} from '@angular/core';
import {CartService} from '../../services/cart.service';
import {CustomerDataService} from '../../../shared/services/customerData.service';
import {HelperService} from '../../../shared/services/helper.service';

@Component({
  selector: 'app-sellout-check',
  templateUrl: './sellout-check.component.html'
})
export class SelloutCheckComponent implements OnInit {

  currentCart: any;
  constructor(public dataService: CustomerDataService,
              public cartService: CartService,
              public helperService: HelperService) { }

  ngOnInit() {
  }

  public onPriceChange(newPrice: number, position:any) {
      if (newPrice >= 0 && newPrice !== position.sellOutNetPerPiece) {
          position.sellOutNetPerPiece = newPrice;
          this.cartService.changePos(position.acquisitionType, position.posId, position.quantity, newPrice);
      }
  }

  public checkSellOut() {
      if (this.cartService.currentCart) {
          for (const position of this.cartService.currentCart.positions) {
              if (position.sellOutNetPerPiece === 0 && position.type === 'SPAREPART') {
                  return true;
              }
          }
      }
      return false;
  }
}
