<div class="global-order-data">
  <div class="heading blue" >
    <span class="clickable" style="cursor: pointer;" (click)="globalAdminService.reset()" routerLink="/global-admin">
        <i class="fa fa-th" aria-hidden="true"></i>
        {{'ADMIN_PANEL.OVERVIEW' | translate}}
    </span>
    <span class="clickable" (click)="globalAdminService.reset()" routerLink="/global-admin/orderhistory">
      <i class="fa fa-caret-right"></i>
      Globale Bestellhistorie
    </span>
    <span class="clickable">
      <i class="fa fa-caret-right"></i>
      <span>{{globalAdminService?.orderDetails?.name}} ({{ globalAdminService?.orderDetails?.created | date:'dd.MM.yyyy' }})</span>
    </span>
  </div>
  <div class="panel-content center-content">

    <div class="table-container" [ngClass]="{ 'big-header': articlesService?.showSellinPrices }" *ngIf="globalAdminService?.orderDetails">
      <h1 class="orderDataHeader" *ngIf="globalAdminService?.orderDetails?.reference">
        <div class="row">
          <div class="col-2">
            Datum:
          </div>
          <div class="col-4">
            <small>{{globalAdminService?.orderDetails?.created | date: 'dd.MM.yyy'}}</small>
          </div>
          <div class="col-2">
            Uhrzeit:
          </div>
          <div class="col-4">
            <small>{{globalAdminService?.orderDetails?.created | date: 'HH:mm'}}</small>
          </div>
        </div>
        <div class="row">
          <div class="col-2">
            Club:
          </div>
          <div class="col-4">
            <small>{{globalAdminService?.orderDetails?.clubName}}</small>
          </div>
          <div class="col-2">
            Benutzer:
          </div>
          <div class="col-4">
            <small>{{globalAdminService?.orderDetails?.loginName}} (ID: {{globalAdminService?.orderDetails?.loginId}})</small>
          </div>
        </div>
        <div class="row">
          <div class="col-2">
            Firma:
          </div>
          <div class="col-10">
            <small>{{globalAdminService?.orderDetails?.firmName}}</small>
          </div>
        </div>
        <div class="row">
          <div class="col-2">
            {{ 'CART.REFERENCE' | translate }}:
          </div>
          <div class="col-10">
            <small>{{ globalAdminService?.orderDetails?.reference }}</small>
          </div>
        </div>
        <div class="row">
          <div class="col-2">
            {{'ORDER.ORDERNUMBER' | translate}}
          </div>
          <div class="col-10">
            <small>{{ globalAdminService?.orderDetails?.platformOrderId }}</small>
          </div>
        </div>
      </h1>

      <table class="simple-table">
        <thead>
        <tr>
          <th width="25%">
            <div>{{ 'CART.MANUFACTURER' | translate }}</div>
            <div>{{ 'CART.ARTICLE_NUMBER' | translate }}</div>
            {{ 'CART.DESCRIPTION' | translate }}
          </th>
          <th width="20%" class="text-center">
            <div>{{'USER_PANEL.SUPPLIER' | translate}}</div>
          </th>
          <th></th>
          <th width="10%" class="text-center">{{ 'CART.ORDERED' | translate }}</th>
          <th width="15%" class="text-center">{{ 'CART.AMOUNT' | translate }}</th>
          <th width="15%" class="text-right">
            <div>{{ 'CART.SINGLE_PRICE' | translate }}</div>
          </th>
          <th width="15%" class="text-right">{{ 'CART.TOTAL_PRICE' | translate}}</th>
        </tr>
        </thead>
        <tbody>
        <tr app-order-history-row [position]="position" *ngFor="let position of globalAdminService?.orderDetails?.positions"></tr>
        <tr>
          <td colspan="6" class="text-right">
            {{ 'CART.SUM_NET' | translate }}
          </td>
          <td class="text-right"  style="padding-right: 15px !important;" *ngIf="userService.sale">
            {{ globalAdminService?.orderDetails?.cartCostDTO.sellOutNetTotalFormatted}}
          </td>
          <td class="text-right"  style="padding-right: 15px !important;" *ngIf="!userService.sale">
            {{ globalAdminService?.orderDetails?.cartCostDTO.sellInNetTotalFormatted}}
          </td>
        </tr>
        <tr>
          <td colspan="6" class="text-right">
            {{ 'CART.VAT' | translate }} {{ globalAdminService?.orderDetails?.cartCostDTO?.vat }} %
          </td>
          <td class="text-right"  style="padding-right: 15px !important;" *ngIf="userService.sale">
            {{ globalAdminService?.orderDetails?.cartCostDTO.sellOutVatTotalTotalFormatted}}
          </td>
          <td class="text-right"  style="padding-right: 15px !important;" *ngIf="!userService.sale">
            {{ globalAdminService?.orderDetails?.cartCostDTO.sellInVatTotalFormatted}}
          </td>
        </tr>
        <tr *ngIf="getDeliveryCostsKeys()?.length > 0 && !userService.sale">
          <td colspan="6" class="text-right">Versndkosten</td><td></td>
        </tr>
        <tr *ngFor="let deliveyCosts of getDeliveryCostsKeys()">
          <td colspan="6" class="text-right">
            {{getFormattedSupplierName(deliveyCosts)}}
          </td>
          <td class="text-right">
            {{globalAdminService?.orderDetails?.cartCostDTO?.deliveryCosts[deliveyCosts].valueFormmatted}}
          </td>
        </tr>
        <tr>
          <td colspan="6" class="text-right">
            {{ 'CART.SUM' | translate }}
          </td>
          <td class="text-right"  style="padding-right: 15px !important;" *ngIf="userService.sale">
            {{ globalAdminService?.orderDetails?.cartCostDTO.sellOutGrossTotalFormatted}}
          </td>
          <td class="text-right"  style="padding-right: 15px !important;" *ngIf="!userService.sale">
            {{ globalAdminService?.orderDetails?.cartCostDTO.sellInGrossTotalFormatted}}
          </td>
        </tr>
        </tbody>
      </table>

      <button class="base-btn back-btn" routerLink="/global-admin/orderhistory">Zurück</button>

    </div>
  </div>
</div>
