<div class="dms-car-selection">
    <!-- CAR FROM CART -->
    <ng-container *ngIf="cartHasCar()">
        <div class="row dms-car-type">
            <div class="col-3 icon">
                <i class="fa fa-car mr-3"></i>
                <span>{{'CAR_SELECTION.TYPE.CART' | translate}}</span>
            </div>
            <div class="col-6">
                <span>{{'CAR_SELECTION.CAR_NAME' | translate:{'car': cartService?.currentCart?.cars[0]?.car.displayName} }}</span>
            </div>
            <div class="col-3">
                <button class="base-btn" (click)="selectCarFromCart()">
                    {{'CAR_SELECTION.TO_ARTICLES' | translate}}
                </button>
            </div>
        </div>
    </ng-container>
    <ng-container *ngFor="let type of dmsCarService.carSelectionSetting">
        <ng-container *ngIf="type !== 'NO_CAR' && type !== 'MANUALLY'">
            <div class="row dms-car-type" *ngIf="configService.getActiveForNameByMode('CAR_SELECTION', type, 'CATALOG')" [ngClass]="{'disabled': !dmsCarService?.getCarForType(type) && cartHasCar()}">
                <!-- LOADING -->
                <ng-container *ngIf="dmsCarService?.getCarForType(type)?.status === 'LOADING'">
                    <div class="col text-center">
                        <i class="fa fa-spinner" [ngClass]="{'fa-pulse': !helperService.browserIsEdge() && !helperService.browserIsIE()}"></i>
                    </div>
                </ng-container>

                <!-- ERROR -->
                <ng-container *ngIf="dmsCarService?.getCarForType(type)?.status === 'ERROR'">
                    <div class="col">
                        <div class="alert alert-danger text-center">
                            <i class="fa fa-times mr-2"></i>
                            <span>{{'CAR_SELECTION.NO_CAR_FOUND' | translate}}</span>
                        </div>
                    </div>
                </ng-container>

                <!-- CONFLICT -->
                <ng-container *ngIf="dmsCarService?.getCarForType(type)?.status === 'CONFLICT'">
                    <div class="col-3 icon">
                        <i class="fa fa-car mr-3"></i>
                        <span>{{'CAR_SELECTION.TYPE.' + type | translate}}</span><br/>
                        <small class="car-param">{{dmsCarService.getParamForType(type)}}</small>
                    </div>
                    <div class="col-9">
                        <span>{{'TOAST_MESSAGES.ERROR_CAR_CONFLICT' | translate}}</span>
                    </div>
                </ng-container>

                <!-- NO DATA -->
                <ng-container *ngIf="!dmsCarService?.getCarForType(type) && !cartHasCar()">
                    <div class="col">
                        <div class="alert alert-info text-center">
                            <i class="fa fa-info-circle mr-2"></i>
                            <span>{{'CAR_SELECTION.NO_' + type | translate}}</span>
                        </div>
                    </div>
                </ng-container>

                <!-- NOT FREE -->
                <ng-container *ngIf="dmsCarService?.getCarForType(type)?.status === 'NOT_FREE'">
                    <div class="col-3 icon">
                        <i class="fa fa-car mr-3"></i>
                        <span>{{'CAR_SELECTION.TYPE.' + type | translate}}</span><br/>
                        <small class="car-param">{{dmsCarService.getParamForType(type)}}</small>
                    </div>
                    <div class="col-6 line-height">
                        <span><b>{{dmsCarService?.getCarForType(type)?.vin}}</b></span><br>
                        <div class="mt-1 pre-line text-secondary" *ngIf="vinInfoText">{{vinInfoText}}</div>
                    </div>

                    <div class="col-3">
                        <button class="base-btn" (click)="dmsCarService.getVinCar(dmsCarService.getCarForType(type)?.vin, true)">
                            {{'CAR_SELECTION.VIN_BUTTON' | translate}}
                        </button>
                    </div>
                </ng-container>

                <!-- SELECT_CAR -->
                <ng-container *ngIf="dmsCarService?.getCarForType(type)?.status === 'SELECT_CAR'">
                    <div class="col-12 mt-3">
                        <div class="alert alert-info text-center">
                            <i class="fa fa-info-circle mr-2"></i>
                            <span>{{'CAR_SELECTION.VIN_SELECTION' | translate}}</span>
                        </div>
                    </div>
                    <div class="col-3 icon">
                        <i class="fa fa-car mr-3"></i>
                        <span>{{'CAR_SELECTION.TYPE.' + type | translate}}</span><br/>
                        <small class="car-param">{{dmsCarService.getParamForType(type)}}</small>
                    </div>
                    <div class="col-9">
                        <div class="row multiple-cars" *ngFor="let car of dmsCarService.getCarForType(type).cars">
                            <!-- ikeCode (repair) -->
                            <ng-container *ngIf="car?.name">
                                <div class="col-9">
                                    {{'CAR_SELECTION.CAR_NAME' | translate: {'car': car?.make?.name + ' ' + car?.name} }}
                                </div>
                                <div class="col-3">
                                    <button class="base-btn"
                                            (click)="dmsCarService.getVinCar(dmsCarService.getCarForType(type).vin, true, dmsCarService.getCarForType(type).natCode, car?.code)">
                                        {{'CAR_SELECTION.SELECT_CAR' | translate}}
                                    </button>
                                </div>
                            </ng-container>

                            <!-- natCode (specifications) -->
                            <ng-container *ngIf="car?.makeDescription">
                                <div class="col-9">
                                    {{'CAR_SELECTION.CAR_NAME' | translate: {'car': car.makeDescription + ' ' + car.typeDescription} }}
                                </div>
                                <div class="col-3">
                                    <button class="base-btn"
                                            (click)="dmsCarService.getVinCar(dmsCarService.getCarForType(type).vin, true, car?.typeETGCode, dmsCarService.getCarForType(type).ikeCode)">
                                        {{'CAR_SELECTION.SELECT_CAR' | translate}}
                                    </button>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>

                <!-- CAR -->
                <ng-container *ngIf="dmsCarService?.getCarForType(type)?.status === 'SUCCESS'">
                    <ng-container *ngIf="getCarsForType(type)?.length <= numberOfVehiclesDisplayed">
                        <ng-container>
                            <div class="col-3 icon">
                                <i class="fa fa-car mr-3"></i>
                                <span>{{'CAR_SELECTION.TYPE.' + type | translate}}</span>
                                <span class="ml-1" *ngIf="!dmsCarService.isTecDocVinSearch && type === 'VIN'">
                                    ({{'CAR_SELECTION.ALLOW.' + dmsCarService?.getCarForType(type)?.allow.toString().toLocaleUpperCase() | translate}})
                                </span><br/>
                                <small class="car-param">{{dmsCarService.getParamForType(type)}}</small>
                            </div>
                            <div class="col-9 line-height">
                                <div class="row multiple-cars" *ngFor="let car of getCarsForType(type)">
                                    <div class="col-9">
                                        {{'CAR_SELECTION.CAR_NAME' | translate:{'car': getCarDisplayName(car)} }}
                                    </div>
                                    <div class="col-3">
                                        <button class="base-btn" (click)="setCarDirect(car, type)">
                                            {{'CAR_SELECTION.TO_ARTICLES' | translate}}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>

                    <!-- DIFFERENT CAR THAN IN CART -->
                    <ng-container *ngIf="dmsCarService.getCarForType(type).hasConflict && (!dmsCarService.getCarForType(type).cars || dmsCarService.getCarForType(type)?.cars?.length === 0)">
                        <div class="col">
                            <div class="alert alert-danger text-center">
                                <i class="fa fa-times mr-2"></i>
                                <span>{{'CAR_SELECTION.DIFFERENT_CAR' | translate}}</span>
                            </div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="getCarsForType(type)?.length > numberOfVehiclesDisplayed">
                        <div class="col-3 icon">
                            <i class="fa fa-car mr-3"></i>
                            <span>{{'CAR_SELECTION.TYPE.' + type | translate}}</span><br/>
                            <small class="car-param">{{dmsCarService.getParamForType(type)}}</small>
                        </div>
                        <div class="col-6 line-height">
                            {{'CAR_SELECTION.MULTIPLE_CARS' | translate}}
                        </div>
                        <div class="col-3">
                            <button class="base-btn" (click)="setCar(type)">
                                {{'CAR_SELECTION.TO_CAR_SELECTION_LIST' | translate}}
                            </button>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </ng-container>

        <!-- NOCAR -->
        <div class="row dms-car-type" *ngIf="type === 'NOCAR'">
            <div class="col-3">
                <i class="fa fa-times"></i>
            </div>
            <div class="col-6 line-height">
                {{'CAR_SELECTION.NO_CAR' | translate}}
            </div>
            <div class="col-3">
                <button class="base-btn" routerLink="/articles" (click)="setCar('NONE')">
                    {{'CAR_SELECTION.NO_CAR_BUTTON' | translate}}
                </button>
            </div>
        </div>

        <!-- MANUALLY -->
        <div class="row dms-car-type" [ngClass]="{'manually': dmsCarService?.getCarForType(type)?.status === 'MANUALLY'}" *ngIf="type === 'MANUALLY'">
            <!-- LOADING -->
            <ng-container *ngIf="dmsCarService?.getCarForType(type)?.status === 'LOADING'">
                <div class="col text-center">
                    <i class="fa fa-spinner" [ngClass]="{'fa-pulse': !helperService.browserIsEdge() && !helperService.browserIsIE()}"></i>
                </div>
            </ng-container>

            <!-- DIFFERENT CAR THAN IN CART -->
            <ng-container *ngIf="dmsCarService.getCarForType(type).hasConflict && (!dmsCarService.getCarForType(type).cars || dmsCarService.getCarForType(type)?.cars?.length === 0)">
                <div class="col">
                    <div class="alert alert-danger text-center">
                        <i class="fa fa-times mr-2"></i>
                        <span>{{'CAR_SELECTION.DIFFERENT_CAR' | translate}}</span>
                    </div>
                </div>
            </ng-container>

            <!-- MANUALLY SELECTION -->
            <ng-container *ngIf="dmsCarService?.getCarForType(type)?.status === 'MANUALLY'">
                <div class="col-3 icon">
                    <i class="fa fa-car mr-3"></i>
                    <span>{{'CAR_SELECTION.TYPE.' + type | translate}}</span>
                    <div class="button-row">
                        <div class="vehicle-type-button" [ngClass]="{ 'active' : dmsCarService.vehicleType === 'PC'}"
                             (click)="dmsCarService.changeVehicleType('PC')">
                            <i class="fa fa-car"></i>
                        </div>

                        <div class="vehicle-type-button" [ngClass]="{ 'active' : dmsCarService.vehicleType === 'MOTORCYCLE'}"
                             (click)="dmsCarService.changeVehicleType('MOTORCYCLE')">
                            <i class="fa fa-motorcycle"></i>
                        </div>

                        <div class="vehicle-type-button" [ngClass]="{ 'active' : dmsCarService.vehicleType === 'CV'}"
                             (click)="dmsCarService.changeVehicleType('CV')">
                            <i class="fa fa-truck"></i>
                        </div>
                    </div>
                </div>
                <div class="col-9">
                    <div class="col-12">
                        <div>
                            <div class="custom-dropdown">
                                <select [(ngModel)]="dmsCarService.selectedProducer"
                                        (change)="dmsCarService.getCarModels()">
                                    <option disabled selected value="null">{{ 'HOME.MANUFACTURER' | translate }}</option>
                                    <option *ngFor="let producer of dmsCarService.carProducers" [value]="producer.id">{{ producer.name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div>
                            <div class="custom-dropdown" ng-class="{disabled: !carModels?.length > 0}">
                                <select [disabled]="!(dmsCarService.carModels?.length > 0)"
                                        [(ngModel)]="dmsCarService.selectedModel"
                                        (change)="dmsCarService.getCarTypes()">
                                    <option value="null" disabled selected>{{ 'HOME.MODEL' | translate }}</option>
                                    <option *ngFor="let model of dmsCarService.carModels" [value]="model.id">{{ (model.name + ' (' +
                                        model.modelYearFrom +' '+['GENERAL.TO' | translate]+' '+ (model.modelYearTo ? model.modelYearTo:' '+['GENERAL.TODAY' | translate]) +
                                        ')') }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div>
                            <div class="custom-dropdown" ng-class="{disabled: !carTypes?.length > 0}">
                                <select ng-options="producer as  for producer in carTypes"
                                        [disabled]="!(dmsCarService.carTypes?.length > 0)"
                                        [(ngModel)]="dmsCarService.selectedType"
                                        (change)="dmsCarService.getCarByManuallySelection()">
                                    <option value="null" disabled selected>{{ 'HOME.TYPE' | translate }}</option>
                                    <option *ngFor="let type of dmsCarService.carTypes" [value]="type.kTypeId">
                                        {{ (type.typeName + ' ' + type.powerKW+'KW / ' + type.powerPS+'PS (' +
                                        type.constructionFrom +' '+['GENERAL.TO' | translate]+' '+ (type.constructionTo ? type.constructionTo:' '+['GENERAL.TODAY' | translate])
                                        +
                                        ')' + (type.engineCodes?.length > 0 ? ' (' + type.engineCodes.toString() + ')' :
                                            '')) }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

            <!-- CAR -->
            <ng-container *ngIf="dmsCarService?.getCarForType(type)?.status === 'SUCCESS'">
                <div class="col-3 icon">
                    <i class="fa fa-car mr-3"></i>
                    <span>{{'CAR_SELECTION.TYPE.' + type | translate}}</span>
                </div>
                <div class="col-6 line-height">
                    {{'CAR_SELECTION.CAR_NAME' | translate:{'car': getDisplayName(type)} }}
                </div>
                <div class="col-3">
                    <button class="base-btn" (click)="setCar(type)">
                        {{'CAR_SELECTION.TO_ARTICLES' | translate}}
                    </button>
                </div>
            </ng-container>
        </div>
    </ng-container>
</div>
