<div class="assortment m-3">
    <div class="row ">
        <div class="col-2">
            Priorität:
        </div>
        <div class="col-5">
        <span class="btn-group float-right cursor-pointer">
              <button (click)="updatePrio( true, 100)"
                      [ngClass]="{'base-btn': true, 'active': (prio <200) }">A</button>
              <button (click)="updatePrio(true, 200)"
                      [ngClass]="{'base-btn': true, 'active': (prio >200 && prio <300) }">B</button>
              <button (click)="updatePrio(true,300)"
                      [ngClass]="{'base-btn': true, 'active': (prio >300 && prio <400) }">C</button>
            </span>
        </div>
        <div class="col-5">
                 <span class="btn-group cursor-pointer">
              <button (click)="updatePrio( false, 1)"
                      [ngClass]="{'base-btn': true, 'active':(modulus(prio) === 1) }">1</button>
                <button (click)="updatePrio( false, 2)"
                        [ngClass]="{'base-btn': true, 'active':(modulus(prio) === 2)  }">2</button>
                 <button (click)="updatePrio( false, 3)"
                         [ngClass]="{'base-btn': true, 'active':(modulus(prio) === 3) }">3</button>
              </span>
        </div>
    </div>
    <fieldset class="material-form-input"
              *ngIf="(state=== 'specific_brand' || state === 'articles')">
        <input id="genArt" type="text"
               placeholder="{{'ASSORTMENT.ARTICLE_SEARCH' | translate}}"
               [(ngModel)]="genArtId"
               [ngbTypeahead]="dataMapperService.searchGenArt"
               [inputFormatter]="dataMapperService.formatterGenArt"
               [resultFormatter]="dataMapperService.formatterGenArt"
               [container]="'body'"
               [editable]='false'/>
        <hr>
        <i class="fa fa-search search-icon"></i>
    </fieldset>
    <fieldset class="material-form-input"
              *ngIf="(state=== 'nogenart' || state === 'specific_article' || state === 'articles')">
        <input id="supplier" type="text"
               placeholder="{{'ASSORTMENT.SUPPLIER_SEARCH' | translate}}"
               [(ngModel)]="dataSupplierNo"
               [ngbTypeahead]="dataMapperService.searchDataSupplier"
               [inputFormatter]="dataMapperService.formatterBrand"
               [resultFormatter]="dataMapperService.formatterBrand"
               [container]="'body'"
               [editable]='false'/>
        <hr>
        <i class="fa fa-search search-icon"></i>
    </fieldset>

    <button class="mt-3 btn btn-success" (click)="createAssortment()"
            [disabled]="!prio ||
              (state === 'nogenart' && !dataSupplierNo) ||
              (state === 'specific_brand' && !genArtId) ||
              (state === 'articles' && !dataSupplierNo && !genArtId) ||
              (state === 'specific_article' && !genArtId && !dataSupplierNo)">
        <i class="fa fa-check mr-2"></i>
        <span>{{'PROMPTS.BUTTONS.SAVE_CHANGES' | translate}}</span>
    </button>
</div>