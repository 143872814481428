<div style="margin-top: 2rem;" class="row" *ngIf="checkSellOut()">
    <div class="col alert alert-danger">
        <div class="text-center">
            <b>{{ 'PRINT_VIEW.OFFER_NO_PRICE_TITLE' | translate }}</b><br/>
            {{ 'PRINT_VIEW.OFFER_NO_PRICE' | translate }}
        </div>
    </div>

    <table class="simple-table ">
        <thead>
        <tr>
            <th class="normalText">
                {{ 'GENERAL.ARTICLE' | translate }}
            </th>
            <th class="normalText">
            </th>
            <th width="100px" class="text-left">{{ 'CART.AMOUNT' | translate }}</th>
            <th width="10%" class="priceText text-center">
                <div>{{ 'CART.SINGLE_PRICE' | translate }} <i class="fa fa-pencil-square-o"></i></div>
            </th>
            <th width="10%" class="priceText text-right">{{ 'CART.TOTAL_PRICE' | translate}}</th>
        </tr>
        </thead>
        <tbody *ngIf="!cartService.cartEmpty">
        <ng-container *ngFor="let position of cartService.currentCart?.positions">
            <tr *ngIf="position.type === 'SPAREPART' && position.sellOutNetPerPiece === 0">
                <td class="brand_ipc">
                    <span class="brand">{{ position.brandName }}</span>
                    &nbsp;<i class="ipc">{{ position.description }}</i>
                </td>
                <td class="brand_ipc">
                    <div class="brand">{{ position.ipc }}</div>
                </td>
                <td class="amount">
                    <span> {{position.quantity}} {{ 'CART.' + position.quantityUnit | translate }}</span>
                </td>
                <td class="price-per-piece text-right">
                    <div class="sellout-price">
                        <app-cart-amount-input style="display: flex; padding-left: 50px;"
                                               [initialAmount]="position.sellOutNetPerPiece"
                                               [showCurrency]="true"
                                               (amountChanged)="onPriceChange($event, position)"
                                               (onMouseClick)="onPriceChange($event, position)"
                                               [floatValue]="true"></app-cart-amount-input>
                    </div>
                </td>
                <td class="total-price priceText text-right">
                    {{ position.sellOutNetTotalFormatted}}
                </td>
            </tr>
        </ng-container>
        </tbody>
    </table>
</div>
