import {Component, OnInit} from '@angular/core';
import {ArticlesService} from '../articles/services/articles.service';
import {CartService} from '../cart/services/cart.service';
import {Router} from '@angular/router';
import {CheckoutService} from './service/checkout.service';
import {CartRestService} from '../cart/services/cart.rest.service';
import {dialog} from '../shared/animations/general.animation';
import {UserService} from '../shared/services/user/user.service';
import {HelperService} from '../shared/services/helper.service';

@Component({
    selector: 'app-checkout',
    templateUrl: './checkout.component.html',
    styleUrls: ['./checkout.component.scss'],
    animations: [dialog]
})
export class CheckoutComponent implements OnInit {

    public bySupplier: any;
    public byArticle: any;
    public summary: any;
    public reference: string;
    public response: any;
    public sendOrderLoader: boolean = false;
    public orderConfirmationMail: string = '';

    constructor(public articlesService: ArticlesService,
                private router: Router,
                public cartService: CartService,
                public cartRestService: CartRestService,
                public checkoutService: CheckoutService,
                public userService: UserService,
                public helperService: HelperService) {

        if (!this.cartService.currentCart) {
            this.cartService.updateCart(false, false, false, false);
            this.cartService.cardInitialized.subscribe(
                (next) => {
                    this.ifCartReady();
                }
            );
        } else {
            this.ifCartReady();
        }

    }

    ngOnInit() {
        if (this.userService.accountData && this.userService.accountData.settings['ORDER_CONFIRMATION_MAIL'].value === 'true') {
            this.orderConfirmationMail = this.userService.accountData.email;
        }
    }

    /*
        STARTUP CHECK
     */

    public verifyQuantity(pos: any) {
        if (pos && pos.preOrderedQuantity) {
            return pos.quantity - pos.preOrderedQuantity;
        } else {
            return pos.quantity;
        }
    }

    public reloadCart() {
        this.response = '';
        this.cartService.updateCartPosCount();
        this.cartService.updateCart(false, false, false, false);
    }

    public getDeliveryCostsKeys() {
        if (this.cartService.currentCart && this.cartService.currentCart.cartCostDTO.deliveryCosts) {
            return Object.keys(this.cartService.currentCart.cartCostDTO.deliveryCosts);
        } else {
            return [];
        }
    }

    public hasActiveArticle(supplier) {
        for (const item of supplier.value) {
            if (item.selected) {
                return true;
            }
        }

        return false;
    }

    public ifCartReady() {
        this.response = '';
        this.listBySupplier();
        this.setDefaultRefference();
        this.summary = this.checkoutService.calcPrices(this.getPositions());
    }

    public display() {
        if (this.byArticle) {
            this.listBySupplier();
        } else {
            this.listByArticle();
        }
    }

    public listBySupplier() {
        this.byArticle = undefined;
        this.bySupplier = this.checkoutService.bySupplier(this.getPositions());
    }

    public listByArticle() {
        this.bySupplier = undefined;
        this.byArticle = this.checkoutService.byArticle(this.getPositions());
    }

    public setDefaultRefference() {
        if (!this.reference) {
            this.reference = this.cartService.currentCart.name;
        }
    }

    public getPositions() {
        return this.cartService.currentCart.positions;
    }

    public compareAcquisitionType(a1: {acquisitionType: string, label: string}, a2: {acquisitionType: string, label: string}): boolean {
        return a1 && a2 ? (a1.acquisitionType === a2.acquisitionType && a1.label === a2.label) : a1 === a2;
    }

    public changeAcquisition(supplier: any, acquisitionType: {acquisitionType: string, label: string}):void {
         this.cartRestService.setAcquisitionType(acquisitionType, supplier.key).subscribe(() => {
                this.cartService.updateCart(false, false, false, false);
            }
        );
    }

    /*
        SUBMIT ORDER
     */

    public isValidMail() {
        if (!this.orderConfirmationMail || this.orderConfirmationMail.trim().length === 0) {
            return false;
        }

        if (this.orderConfirmationMail.indexOf('@') < 0) {
            return false;
        }

        const tempAt = this.orderConfirmationMail.substring(this.orderConfirmationMail.indexOf('@') + 1);
        if (tempAt.indexOf('.') < 0) {
            return false;
        }

        const tempDot = this.orderConfirmationMail.substring(this.orderConfirmationMail.lastIndexOf('.') + 1);
        if (tempDot.trim().length === 0) {
            return false;
        }

        return true;
    }

    public sendOrder() {
        if (this.userService.allow('ORDER') && !this.sendOrderLoader) {
            if (this.isValidMail() && this.userService.accountData.settings['ORDER_CONFIRMATION_MAIL'].value === 'true'
                || this.userService.accountData.settings['ORDER_CONFIRMATION_MAIL'].value === 'false') {
                this.sendOrderLoader = true;
                let reference = this.reference;
                let list = [];
                for (let prod of this.getPositions()) {
                    if (this.checkoutService.orderable(prod) && prod.selected) {
                        list.push(prod.posId);
                    }
                }
                this.cartRestService.sendOrder( list, this.orderConfirmationMail, reference).subscribe(
                    (success) => {
                        this.sendOrderLoader = false;
                        this.cartService.updateCartList();
                        this.response = success;
                        if (success.submitted) {
                            this.cartService.onCompleteOrder.emit();
                        } else if (success.checkAndRetry) {
                            switch (success.checkAndRetryReason) {
                                case 'PRICES_CHANGED':
                                    this.helperService.showNotification('TOAST_MESSAGES.ERROR_PRICES_CHANGED', 'warning');
                                    break;
                                case 'SUPPLIER_NOT_ACTIVE':
                                    this.helperService.showNotification('TOAST_MESSAGES.ERROR_SUPPLIER_NOT_ACTIVE', 'error');
                                    break;
                                case 'NO_POSITION_SELECTED':
                                    this.helperService.showNotification('TOAST_MESSAGES.ERROR_NO_POSITION_SELECTED', 'error');
                                    break;
                            }
                        } else if (success.errorOccured) {
                            this.helperService.showNotification('TOAST_MESSAGES.ERROR_SEND_ORDER', 'error');
                        }
                    },
                    (error) => {
                        this.sendOrderLoader = false;
                        this.helperService.showNotification('TOAST_MESSAGES.ERROR_SEND_ORDER', 'error');
                    }
                )
            } else {
                this.helperService.showNotification('TOAST_MESSAGES.ERROR_MAIL_NOT_VALID', 'error');
            }
        }
    }
}
