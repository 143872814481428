import {
    AfterContentChecked,
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from '@angular/core';
import {UserService} from '../../shared/services/user/user.service';
import {itemAnimation} from '../../shared/animations/general.animation';
import {CarService} from '../../shared/services/car/car.service';
import {ArticlesService} from '../services/articles.service';
import {Location} from '@angular/common';
import {HelperService} from '../../shared/services/helper.service';
import {IArticleListSearchResponse, IArticleSmall} from '../../shared/interfaces/DTO/article/articleListSearchResponse';
import {ArticlesRestService} from '../services/articles.rest.service';
import {IItem} from '../interfaces/article';
import {MaintenanceService} from '../../maintenance/services/maintenance.service';
import {WorkService} from '../../work-data/services/work.service';
import {Router} from '@angular/router';
import {Subscription} from "rxjs";


@Component({
    selector: 'app-articles-search-result',
    templateUrl: './articles-search-result.component.html',
    styleUrls: ['./articles-search-result.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: [itemAnimation]
})

export class ArticlesSearchResultComponent implements OnInit, AfterViewInit, AfterContentChecked, OnDestroy {
    private possibleAvailabilities = [
        {name: "AVAILABLE", sortNo: 0},
        {name: "SHIPPING24", sortNo: 1},
        {name: "SHIPPING48", sortNo: 2},
        {name: "OTHER_SHOP", sortNo: 3},
        {name: "ASAP", sortNo: 4},
        {name: "BACKORDER", sortNo: 5},
        {name: "SPECIAL_ORDER", sortNo: 6},
        {name: "OWN_STOCK", sortNo: 7},
        {name: "NOT_AVAILABLE", sortNo: 8},
        {name: "CONNECTION_BROKEN", sortNo: 9},
        {name: "INVALID_CREDENTIALS", sortNo: 10},
        {name: "MISSING_ARTICLE_INFORMATION", sortNo: 11},
    ];
    @Input() service: any;

    private resortEventSubscription: Subscription;
    private readonly pageForSortButton: number = 2;

    constructor(public userService: UserService,
                private carService: CarService,
                public articlesService: ArticlesService,
                public maintenanceService: MaintenanceService,
                public workService: WorkService,
                private changeDetectorRef: ChangeDetectorRef,
                public location: Location,
                private articlesRestService: ArticlesRestService,
                public helperService: HelperService,
                private router: Router) {

    }

    ngAfterViewInit(): void {
    }

    ngOnInit(): void {
        if (this.service.selectedArticle && this.service.selectedArticle.pseudoArticle) {
            this.articlesService.hidePartsListPseudo = true;
        } else {
            this.articlesService.hidePartsListPseudo = false;
        }
        if (this.changeDetectorRef && !this.changeDetectorRef['destroyed']) {
            this.changeDetectorRef.detectChanges();
        }

        this.resortEventSubscription = this.articlesService.resortEmitterNewPageNeeded.subscribe((needNewPage: boolean) => {
            this.loadArticlesAndSortByAvailabilityAndPrice(needNewPage);
        });
    }

    ngOnDestroy() {
        this.resortEventSubscription.unsubscribe();
        this.articlesService.selectedArticle = undefined;
        this.maintenanceService.selectedArticle = undefined;
        this.workService.selectedArticle = undefined;
        this.changeDetectorRef.detach();
    }

    ngAfterContentChecked() {
        this.service.emitLastSearch();
    }

    public toggleAlternative(article: any) {
        if (article && article.showAlternative) {
            article.showAlternative = false;
        } else if (article && !article.showAlternative) {
            article.showAlternative = true;
            this.getAlternativeArticles(article);
        }
    }

    public getAlternativeArticles(article: IArticleSmall) {
        if (article && !article.directSearchResponse) {
            article.loadDirectSearchArticles = true;
            this.articlesRestService.directSearchArticles(article.ipc, [], false, 1, 20).subscribe(
                (articles) => {
                    article.loadDirectSearchArticles = false;
                    article.directSearchResponse = this.filterAlternativeArticles(articles, article);
                },
                (error) => {
                    article.loadDirectSearchArticles = false;
                    this.helperService.showNotification('TOAST_MESSAGES.ERROR_GET', 'error');
                });
        }
    }

    private filterAlternativeArticles(articles: IArticleListSearchResponse, article: IArticleSmall) {
        const temp = [];
        for (const alternative of articles.items) {
            for (const genart of alternative.genArtIds) {
                if (article.genArtIds.indexOf(genart) > -1 && (alternative.ipc !== article.ipc)
                    && (alternative.brandName !== article.brandName)) {
                    temp.push(alternative);
                    break;
                }
            }
        }
        const directSearchResponse = {} as IArticleListSearchResponse;
        directSearchResponse.items = temp;
        return directSearchResponse;
    }

    public onEnd() {
        if (this.service.searchResult && this.service.searchResult.count > 0) {
            if (this.userService.enableInfiniteScroll
                && ((this.service.searchResult.count / 20) <= this.service.searchResult.page)
                && !this.service.infinityLoading && !this.service.articlesLoading) {
                this.articlesService.scrollEndEmitter.emit(true);
            } else {
                this.articlesService.scrollEndEmitter.emit(false);
            }
            return this.userService.enableInfiniteScroll
                && ((this.service.searchResult.count / 20) <= this.service.searchResult.page)
                && !this.service.infinityLoading && !this.service.articlesLoading;
        } else {
            this.articlesService.scrollEndEmitter.emit(false);
            return false;
        }

    }

    public goToPage(page: any) {
        this.service.expanded_right = false;
        this.service.updateArticleSearch(page, this.service.searchResult.activeFilters, this.service.searchResult.searchForAllArticles, this.articlesService.supplierType);
    }

    public checkGenArtNumber(article: IArticleSmall) {
        let hasGenArt = false;

        if (this.userService.accountData && this.userService.accountData.comparisonSearchGenericArticleNumbers && this.userService.accountData.comparisonSearchGenericArticleNumbers.length > 0) {
            article.genArtIds.forEach((genArt) => {
                if (this.userService.accountData.comparisonSearchGenericArticleNumbers.indexOf(genArt) > -1) {
                    hasGenArt = true;
                }
            })
        }
        return hasGenArt;
    }

    private changeSelectedItem(item: any) {
        this.userService.navigationHelper = 'right';
        if (!this.service.selectedArticle || ((this.service.selectedArticle.id !== item.id) || (this.service.selectedArticle.brandName !== item.brandName))) {
            this.service.expanded_right = !this.service.expanded_right;
            let dropCar = false;
            if ((item.kTypeId === 0 || item.adcTypeId === 0) && this.carService.selectedCar) {
                item.kTypeId = this.carService.selectedCar.kTypeId;
                item.adcTypeId = this.carService.selectedCar.adcTypeId;
                dropCar = true;
            }
            if (this.router.url.includes('/maintenance/') || this.router.url.includes('/work/')) {
                this.service.updateArticleDetails(item.id, item.kTypeId, item.adcTypeId, item, dropCar, 'AFTER_MARKET');
            } else {
                this.service.updateArticleDetails(item.id, item.kTypeId, item.adcTypeId, item, dropCar, this.articlesService.supplierType);
            }
        }
    }

    public openPseudoArticle(article: any, selectedArticle: IItem) {
        this.changeSelectedItem(article);

        if (!this.service.selectedArticle) {
            this.service.selectedArticle = article;
        }
        this.service.updateByArticlePartlist().then(() => this.service.setPseudoArticle(this.service.selectedArticle, this.service.partList));
        this.service.hidePartsListPseudo = true;
    }

    public getAvailabilitySchedule(articleId: number, withSellin?: boolean): any[] {
        if (this.service.availability.get(articleId) && this.service.availability.get(articleId).schedule) {
            if (withSellin) {
                this.service.availability.get(articleId).schedule[0].sellInNetPerPiece = this.service.availability.get(articleId).sellInNetPerPiece;
                return this.service.availability.get(articleId).schedule;
            } else {
                return this.service.availability.get(articleId).schedule;
            }
        } else {
            return [];
        }
    }


    private loadArticlesAndSortByAvailabilityAndPrice(needNewPage: boolean): void {
        this.articlesService.showSortLoading$.next(true);
        if (needNewPage) {

            // get second Page and append it to the result
            if (this.service.directSearchValue?.length > 0) {
                this.service.updateArticleSearch(this.pageForSortButton, this.service.searchResult.activeFilters, this.service.searchResult.searchForAllArticles, this.service.supplierType);
            } else {
                this.service.updateTreeBySelectedCategory(this.pageForSortButton, this.service.searchResult.activeFilters, this.service.searchForAllArticles);
            }

            // wait for availability then sort
            this.service.availabilityDone.subscribe((gotAvailability: boolean) => {
                if (gotAvailability) {
                    this.sortByAvailabilityAndPrice();
                }
            });
        } else {
            this.sortByAvailabilityAndPrice();
        }
    }

    public sortByAvailabilityAndPrice(): void {
        this.articlesService.showSortLoading$.next(true);
        const filteredArticle = [];

        //append availability, price and sortNo to the items
        this.service.resultItems.forEach(item => {
            const availabilityInfos = this.getAvailabilitySchedule(item.id, true)?.[0];
            item.availability = availabilityInfos?.availability;
            item.sellInNetPerPiece = availabilityInfos?.sellInNetPerPiece ?? 0;
            if (item.availability) {
                item.sortNo = this.possibleAvailabilities.find(element => element.name === item.availability).sortNo;
            } else {
                item.sortNo = 99;
            }
            item.sellInNetPerPiece === 0 ? item.price = Number.MAX_SAFE_INTEGER : item.price = item.sellInNetPerPiece;

            filteredArticle.push(item);
        })

        //sort Array by SortNo and Price
        this.service.resultItems = filteredArticle.sort(function (a, b) {
            return a.sortNo - b.sortNo || a.price - b.price;
        });

        setTimeout(() => {
            this.articlesService.showSortLoading$.next(false);
            // scroll to top in the article list
            if (document.getElementById('panelContent')) {
                document.getElementById('panelContent').scroll(0, 0);
            }
        }, 1000);
    }
}
