<div class="cart">
    <div class="content sidebar-opener-wrapper">
        <div class="sidebar-opener" [ngClass]="userService?.getNavigation()">
            <ul>
                <li (click)="userService?.toggleNavigation()">
                    <i class="fa fa-chevron-right"></i>
                </li>
            </ul>
        </div>
        <div class="left-panel no-float no-padding" [ngClass]="userService?.getNavigation()">
            <div class="panel-content left-sidebar tree-sidebar">
                <div class="inner-content">
                    <app-cart-list *ngIf="!dmsDataService.isCatalogMode()"></app-cart-list>
                </div>
            </div>
        </div>

        <div class="center-panel no-float" [ngClass]="userService?.getNavigation()">
            <app-default-loading-page
                    *ngIf="!cartService.currentCart && location.path().endsWith('/cart')"></app-default-loading-page>
            <!--Menü Warenkorb-->
            <div class="heading blue">
                <span class="clickable" routerLink="/cart"
                      (click)="cartService.showDocument = undefined; maintenanceService.showPdf = false">
                    <i class="fa fa-th open" routerLink="/cart"></i>
				    <span *ngIf="!cartService.isOrder() && !cartService.isPreview()">{{ 'CART.TITLE' | translate }}</span>
				    <span *ngIf="cartService.isOrder() || cartService.isPreview()">{{ 'CART.ORDER' | translate }}</span>
                </span>
                <!--edit cart name-->
                <span *ngIf="location.path().endsWith('/cart') && !userService.getCatalogMode() && !cartService.isOrder() && !cartService.isPreview() && !cartService.showDocumentWithLink && !(cartService?.cartCount?.calculationData)">
                    <i class="fa fa-caret-right"></i>
                    <span class="wkChange" [ngClass]="{'long': cartService?.currentCart?.name?.length > 20}">
                        <input (keyup)="checkCartName(d.value)" (keydown.enter)="renameCart(d.value)" id="d" #d
                               [type]="'text'" [value]="cartService?.currentCart?.name">
                        <span *ngIf="!hasFocus()" (click)="d.focus()">({{'CART.RENAME' | translate}})</span>
                    </span>
                    <button *ngIf="showSave" class="icon-btn" (click)="renameCart(d.value)"><i class="fa fa-check"></i></button>
                </span>
                <span *ngIf="location.path().endsWith('/cart') && cartService?.cartCount?.calculationData || cartService.isOrder() || cartService.isPreview()">
                    <i class="fa fa-caret-right"></i>
                    <span>{{cartService?.currentCart?.name}}</span>
                </span>
                <span *ngIf="location.path().endsWith('/article') && userService.getCatalogMode() && !cartService.showDocumentWithLink">
                    <i class="fa fa-caret-right"></i>
                    {{cartService?.currentCart?.name}}
                </span>
                <span *ngIf="location.path().endsWith('/article')">
                    <i class="fa fa-caret-right"></i>
                    <span>
                        <span>{{'CART.ADD_ARTICLE_POPUP.TITLE' | translate}}</span>
                    </span>
                </span>
                <span *ngIf="location.path().endsWith('/offer') && !cartService.showDocumentWithLink">
                    <i class="fa fa-caret-right"></i>
                    <span>
                        <span>{{'PRINT_VIEW.PRINT' | translate}}</span>
                    </span>
                </span>

                <!-- Spezialwerkzeuge -->
                <ng-container *ngIf="location.path().includes('/raw-tools')">
                    <i class="fa fa-caret-right"></i>
                    <span>{{'CART.RAWTOOL' | translate}}</span>
                </ng-container>
                <button class="print-btn pull-right"
                        (click)="createRawToolsPdf()"
                        *ngIf="location.path().includes('/raw-tools')">
                    <i class="fa fa-print"></i>
                    <span>{{'PRINT_VIEW.PRINT' | translate}}</span>
                </button>

                <!-- Kommentar -->
                <ng-container *ngIf="location.path().endsWith('/comment')">
                    <i class="fa fa-caret-right"></i>
                    <span *ngIf="!(cartService?.currentCart?.comment.length > 0)">{{'CART.COMMENT.ADD_COMMENT' | translate}}</span>
                    <span *ngIf="cartService?.currentCart?.comment.length > 0">{{'CART.COMMENT.EDIT_COMMENT' | translate}}</span>
                </ng-container>

                <!-- Dokumente -->
                <ng-container *ngIf="cartService?.showDocumentWithLink">
                    <span>
                        <i class="fa fa-caret-right"></i>
                        <span>
                            <span>{{cartService?.showDocumentWithLink?.text}}</span>
                        </span>
                    </span>

                    <button *ngIf="cartService?.showDocumentWithLink?.type !== 'PDF'" class="print-btn pull-right"
                            (click)="printIframe('documents')">
                        <i class="fa fa-print"></i>
                        <span>{{'PRINT_VIEW.PRINT' | translate}}</span>
                    </button>
                    <button *ngIf="cartService?.showDocumentWithLink?.type === 'PDF'" class="print-btn pull-right"
                            (click)="printBlob()">
                        <i class="fa fa-print"></i>
                        <span>{{'PRINT_VIEW.PRINT' | translate}}</span>
                    </button>
                </ng-container>

                <!-- Serviceplan -->
                <ng-container *ngIf="location.path().endsWith('/service')">
                    <span>
                        <i class="fa fa-caret-right"></i>
                        <span>
                            <span>{{'MAINTENANCE.PLAN' | translate}}</span>
                        </span>
                    </span>

                    <button class="print-btn pull-right" (click)="printIframe('serviceplan')"
                            *ngIf="maintenanceService?.serviceplanUrl?.length > 0">
                        <i class="fa fa-print"></i>
                        <span>{{'PRINT_VIEW.PRINT' | translate}}</span>
                    </button>

                    <button class="print-btn pull-right" (click)="showMail = !showMail"
                            *ngIf="maintenanceService?.serviceplanUrl?.length > 0">
                        <i class="fa fa-envelope-o"></i>
                        <span>{{'PROMPTS.BUTTONS.AS_MAIL' | translate}}</span>
                    </button>
                </ng-container>

                <!-- Hersteller Info -->
                <ng-container *ngIf="location.path().endsWith('/cart/info')">
                    <span>
                        <i class="fa fa-caret-right"></i>
                        <span>{{'MAINTENANCE.MANUFACTURER_INFO' | translate}}</span>
                    </span>

                    <button class="print-btn pull-right" (click)="printIframe('tecrmi-manual')">
                        <i class="fa fa-print"></i>
                        <span>{{'PRINT_VIEW.PRINT' | translate}}</span>
                    </button>
                </ng-container>

                <!-- Füllmengen -->
                <ng-container *ngIf="location.path().endsWith('/cart/specs')">
                    <span>
                        <i class="fa fa-caret-right"></i>
                        <span>{{'MAINTENANCE.MANUFACTURER_SPEC' | translate}}</span>
                    </span>

                    <button class="print-btn pull-right"
                            (click)="maintenanceService.createPdf(maintenanceService.maintenanceSpecs)"
                            *ngIf="!maintenanceService.showPdf">
                        <i class="fa fa-file-pdf-o"></i>
                        <span>{{'PRINT_VIEW.TITLE' | translate}}</span>
                    </button>
                    <button class="print-btn pull-right"
                            (click)="maintenanceService.showPdf = !maintenanceService.showPdf"
                            *ngIf="maintenanceService.showPdf">
                        <i class="fa fa-close"></i>
                        <span>{{'PRINT_VIEW.CLOSE' | translate}}</span>
                    </button>
                </ng-container>

                <!-- Reifeninformationen -->
                <ng-container *ngIf="location.path().endsWith('/cart/tyre')">
                    <span>
                        <i class="fa fa-caret-right"></i>
                        <span>{{'CART.TYRE_INFORMATION' | translate}}</span>
                    </span>

                    <button class="print-btn pull-right" (click)="tyreService.createPdf(false)"
                            *ngIf="!tyreService.showPdf">
                        <i class="fa fa-file-pdf-o"></i>
                        <span>{{'PRINT_VIEW.TITLE' | translate}}</span>
                    </button>
                    <button class="print-btn pull-right" (click)="tyreService.showPdf = !tyreService.showPdf"
                            *ngIf="tyreService.showPdf">
                        <i class="fa fa-close"></i>
                        <span>{{'PRINT_VIEW.CLOSE' | translate}}</span>
                    </button>
                </ng-container>

                <!-- Wechselintervalle -->
                <ng-container *ngIf="location.path().endsWith('/cart/change')">
                    <span>
                        <i class="fa fa-caret-right"></i>
                        <span>{{'MAINTENANCE.CHANGE_INTERVAL' | translate}}</span>
                    </span>

                    <button class="print-btn pull-right"
                            (click)="maintenanceService.createPdf(maintenanceService.maintenanceChangeInterval)"
                            *ngIf="!maintenanceService.showPdf">
                        <i class="fa fa-file-pdf-o"></i>
                        <span>{{'PRINT_VIEW.TITLE' | translate}}</span>
                    </button>
                    <button class="print-btn pull-right"
                            (click)="maintenanceService.showPdf = !maintenanceService.showPdf"
                            *ngIf="maintenanceService.showPdf">
                        <i class="fa fa-close"></i>
                        <span>{{'PRINT_VIEW.CLOSE' | translate}}</span>
                    </button>
                </ng-container>

                <!-- Rückruf -->
                <ng-container *ngIf="location.path().endsWith('/cart/recall')">
                    <span>
                        <i class="fa fa-caret-right"></i>
                        <span>{{'CAR_HISTORY.WARNING.TITLE' | translate}}</span>
                    </span>

                    <button class="print-btn pull-right" (click)="createPdf()"
                            *ngIf="!maintenanceService.showPdf && cartService?.showDocument?.car?.serviceInfos?.length > 0">
                        <i class="fa fa-file-pdf-o"></i>
                        <span>{{'PRINT_VIEW.TITLE' | translate}}</span>
                    </button>
                    <button class="print-btn pull-right"
                            (click)="maintenanceService.showPdf = !maintenanceService.showPdf"
                            *ngIf="maintenanceService.showPdf">
                        <i class="fa fa-close"></i>
                        <span>{{'PRINT_VIEW.CLOSE' | translate}}</span>
                    </button>
                </ng-container>

                <!-- Ausstattungsdetails -->
                <ng-container *ngIf="location.path().endsWith('/cart/cardetails')">
                    <span>
                        <i class="fa fa-caret-right"></i>
                        <span>{{'CAR_DETAILS.EQUIPMENT.TITLE' | translate}}</span>
                    </span>

                    <button class="print-btn pull-right" (click)="technicalDataService.createPdf()"
                            *ngIf="!technicalDataService.showPdf">
                        <i class="fa fa-file-pdf-o"></i>
                        <span>{{'PRINT_VIEW.TITLE' | translate}}</span>
                    </button>
                    <button class="print-btn pull-right"
                            (click)="technicalDataService.showPdf = !technicalDataService.showPdf"
                            *ngIf="technicalDataService.showPdf">
                        <i class="fa fa-close"></i>
                        <span>{{'PRINT_VIEW.CLOSE' | translate}}</span>
                    </button>
                </ng-container>

                <span class="verbundarbeit clickable"
                      *ngIf="!cartService.showCustomArticle && !cartService.showDocumentWithLink && location.path().endsWith('/cart') && !cartService.isOrder()">
                    <app-checkbox [isChecked]="cartService?.currentCart?.useCompositeWorkTimes"
                                  (onChange)="toggleCompositeWorkTime()"
                                  [labelText]="'CART.COMPOSITE_WORK_TIME' | translate "></app-checkbox>
                </span>

                <div ngbDropdown placement="bottom-right" class="d-inline-block clickable"
                     *ngIf="location.path().endsWith('/cart') && !cartService.showDocumentWithLink">
                    <button class="btn clickable" id="dropdownWarenkorb" ngbDropdownToggle>
                        <i class="fa fa-ellipsis-v"></i>
                    </button>
                    <div ngbDropdownMenu class="dropDownMenu" aria-labelledby="dropdownWarenkorb">
                        <button class="dropdown-item" (click)="cartService.createCart('')"
                                *ngIf="!userService.getCatalogMode()">
                            <i class="fa fa-plus"></i>
                            <span>{{ 'PROMPTS.BUTTONS.NEW_CARD' | translate }}</span>
                        </button>
                        <button class="dropdown-item" (click)="cartService.updateCart(false, false, false, false)">
                            <i class="fa fa-refresh"></i>
                            <span>{{ 'PROMPTS.BUTTONS.REFRESH' | translate }}</span>
                        </button>
                        <button class="dropdown-item" (click)="cartService.clearCart(cartService.currentCart.id)"
                                *ngIf="!cartService.isOrder()">
                            <i class="fa fa-trash-o"></i>
                            <span>{{'CART.CLEAR_CART' | translate}}</span>
                        </button>
                        <button class="dropdown-item" (click)="deleteCart(cartService.currentCart.id)"
                                *ngIf="!userService.getCatalogMode() && !cartService.isOrder()">
                            <i class="fa fa-trash"></i>
                            <span>{{ 'CART.DELETE_CART' | translate }}</span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="panel-content center-content" *ngIf="location.path().endsWith('/service')">
                <div class="mail" [ngClass]="{'show': showMail}">
                    <span>{{'MAIL.MAIL' | translate}}</span><br/>
                    <app-material-form-input [label]="'E-Mail'" [text]="userService?.accountData?.email"
                                             (textChange)="mail = $event"></app-material-form-input>
                    <button class="base-btn"
                            (click)="showMail = false">{{'PROMPTS.BUTTONS.CANCEL' | translate}}</button>
                    <button class="base-btn success mt-0"
                            (click)="maintenanceService.sendPerMail(mail); showMail = false">{{'MAIL.SEND' | translate}}</button>
                </div>
            </div>
            <div class="panel-content center-content white-background" *ngIf="cartService?.showDocumentWithLink">
                <iframe *ngIf="cartService?.showDocumentWithLink?.type !== 'PDF'" width="100%"
                        onLoad="this.style.height=(this.contentDocument.body.scrollHeight + 30) + 'px';"
                        [src]="cartService?.showDocumentWithLink?.url | iFrame" id="documents"></iframe>
                <app-default-loading-page
                        *ngIf="cartService?.showDocumentWithLink?.type === 'PDF' && !documentPdf"></app-default-loading-page>
                <pdf-viewer *ngIf="cartService?.showDocumentWithLink?.type === 'PDF'"
                            [src]="documentPdf"
                            [autoresize]="true"
                            [render-text]="false"
                            [show-all]="true"
                            style="display: block; width: calc(100% + 26px)"
                ></pdf-viewer>
            </div>

            <router-outlet #route="outlet" *ngIf="!cartService.showDocumentWithLink"></router-outlet>
        </div>

        <div class="right-panel no-float" [ngClass]="userService?.getNavigation()"
             *ngIf="!(location.path().endsWith('/article-selection'))">
            <div class="top-section">

                <div class="panel-content">
                    <div class="inner-content" *ngIf="!cartService.cartLoading">

                        <div class="text-right"
                             *ngIf="configService.allowOffers() && !cartService.isPreview() && !cartService.isOrder()"
                             style="margin: 1rem;">
                            <button class="base-btn" *ngIf="!cartService.cartLoading" routerLink="/cart/offer"
                                    (click)="pdfService.updateDmsData(); cartService.backLink = '/cart'; maintenanceService.showPdf = false">
                                <i class="fa fa-file-pdf-o"></i> {{'PRINT_VIEW.PRINT_OFFER' | translate}}</button>
                        </div>


                        <!-- Warenkorb -->
                        <ng-container *ngIf="!cartService.history && cartService.currentCart">

                            <!-- Verkaufsmodus -->
                            <ng-container
                                    *ngIf="userService.sale && !dmsDataService.isCatalogMode() && !cartService.isPreview()">
                                <div class="card d-contents">

                                    <div class="cart-price-spinner" *ngIf="cartService.updateCartPrice">
                                        <i class="fa fa-spinner"
                                           [ngClass]="{'fa-pulse': !helperService.browserIsIE() && !helperService.browserIsEdge()}"></i>
                                    </div>

                                    <table class="simple-table text-right" *ngIf="!cartService.updateCartPrice">
                                        <tbody>

                                        <tr>
                                            <td colspan="2">
                                                <b>{{ cartService?.currentCart?.count }} {{ 'GENERAL.ARTICLE' | translate }}</b>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="text-left">
                                                {{'CART.SUM_NET' | translate}}:
                                            </td>
                                            <td class="text-right">
                                                {{cartService.currentCart.cartCostDTO.sellOutNetTotalFormatted}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="text-left">
                                                {{'CART.VAT' | translate}} {{cartService.currentCart.cartCostDTO.vat}}%:
                                            </td>
                                            <td class="text-right">
                                                {{cartService.currentCart.cartCostDTO.sellOutVatTotalTotalFormatted}}
                                            </td>
                                        </tr>
                                        <tr class="cartSum">
                                            <td class="text-left">
                                                {{'CART.SUM' | translate}}:
                                            </td>
                                            <td class="text-right">
                                                {{cartService.currentCart.cartCostDTO.sellOutGrossTotalFormatted}}
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>

                                    <div class="text-right" style="margin: 1rem;" tooltip tooltipClass="centerToolTip"
                                         tooltipPosition="center" tooltipContent="{{'CART.TOOLTIP_SALE' | translate}}">
                                        <button class="order-btn"
                                                *ngIf="userService.sale && cartService?.isNewestCart() && !cartService.isOrder() && !cartService.updateCartPrice"
                                                disabled>
                                        <span>
                                             {{ 'CART.ORDER_BUTTON' | translate }}
                                        </span>
                                        </button>
                                    </div>

                                </div>


                            </ng-container>

                            <!-- Checkout -->
                            <ng-container
                                    *ngIf="(!userService.sale && cartService?.currentCart?.count > 0 && (!dmsDataService.isCatalogMode() || (dmsDataService.isCatalogMode() && userService.accountData.settings?.CATALOG_ORDER_MODE?.value != 'DENY' )) && !cartService.isOrder() && !cartService.isPreview())">
                                <div class="card d-contents">

                                    <div class="cart-price-spinner" *ngIf="cartService.updateCartPrice">
                                        <i class="fa fa-spinner"
                                           [ngClass]="{'fa-pulse': !helperService.browserIsIE() && !helperService.browserIsEdge()}"></i>
                                    </div>

                                    <table class="simple-table text-right" *ngIf="!cartService.updateCartPrice">
                                        <tbody>

                                        <tr>
                                            <td colspan="2">
                                                <b>{{ cartService?.checkedCount }} {{ 'GENERAL.ARTICLE' | translate }}</b>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="text-left">
                                                {{'CART.SUM_NET' | translate}}:
                                            </td>
                                            <td class="text-right">
                                                {{cartService.currentCart.cartCostDTO.sellInNetTotalFormatted}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="text-left">
                                                {{'CART.VAT' | translate}} {{cartService.currentCart.cartCostDTO.vat}}%:
                                            </td>
                                            <td class="text-right">
                                                {{cartService.currentCart.cartCostDTO.sellInVatTotalFormatted}}
                                            </td>
                                        </tr>

                                        <ng-container *ngIf="hasDeliveryCosts(cartService?.currentCart)">
                                            <tr>
                                                <td colspan="2">
                                                    <b>{{'CART.DELIVERY_COSTS' | translate}}</b>
                                                </td>
                                            </tr>
                                            <tr *ngFor="let key of getDeliveryCostsKeys(cartService?.currentCart)">
                                                <td class="text-left">
                                                    {{cartService.currentCart.supplierInformation[key]?.supplierName}}
                                                </td>
                                                <td class="text-right">
                                                    {{cartService?.currentCart?.cartCostDTO.deliveryCosts[key].valueFormmatted}}
                                                </td>
                                            </tr>
                                        </ng-container>

                                        <tr class="cartSum">
                                            <td class="text-left">
                                                {{'CART.SUM' | translate}}:
                                            </td>
                                            <td class="text-right">
                                                {{cartService?.currentCart?.cartCostDTO.sellInGrossTotalWithDeliveryCostsFormatted}}
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>

                                    <button class="order-btn" (click)="checkout()"
                                            style="margin: 1rem; margin-left: auto; margin-right: auto;"
                                            *ngIf="userService.allow('ORDER') && cartService?.isNewestCart() && !cartService.isOrder() && !cartService.updateCartPrice && cartService?.checkedCount > 0 && cartService.hasOrderableArticles()">
                                        {{ 'CART.ORDER_BUTTON' | translate }}
                                    </button>
                                    <button class="order-btn outline" (click)="cartService.showCloseCartWarning = true"
                                            style="margin: 1rem; margin-left: auto; margin-right: auto;"
                                            *ngIf="showCloseCartButton && userService.allow('ORDER') && cartService?.isNewestCart() && !cartService.updateCartPrice && cartService?.currentCart?.count > 0 && !cartService.hasOrderableArticles()">
                                        {{'CART.CLOSE_CART_BUTTON' | translate}}
                                    </button>

                                    <div *ngIf="!userService.allow('ORDER') && cartService?.isNewestCart() && !cartService.updateCartPrice && cartService?.checkedCount > 0"
                                         tooltip tooltipClass="centerToolTip" tooltipPosition="center"
                                         tooltipContent="{{'CART.TOOLTIP_NO_RIGHTS' | translate}}">
                                        <button class="order-btn"
                                                style="margin: 1rem; margin-left: auto; margin-right: auto;" disabled>
                                            {{ 'CART.ORDER_BUTTON' | translate }}
                                        </button>
                                    </div>

                                </div>

                            </ng-container>

                            <!-- cars in cart -->

                            <div class="carsInCart"
                                 *ngIf="cartService.currentCart?.cars?.length > 0 && !cartService.cartLoading && !cartService.isPreview()">
                                <span class="carHeader">{{'CART.CAR.CARS_IN_CART' | translate}}</span>

                                <div class="row" *ngFor="let car of cartService.currentCart.cars">
                                    <div class="col-8"
                                         (click)="carService.selectCar(car.car, true, true); maintenanceService.showPdf = false"
                                         routerLink="/cart">
                                        <span class="carName"
                                              [ngClass]="{'selected': carService?.selectedCar?.displayName === car.car.displayName}">
                                            {{car?.car?.displayName}}
                                        </span>
                                    </div>
                                    <div class="col-4 d-flex justify-content-end">
                                        <button class="base-btn servicePlanButton"
                                                *ngIf="cartService.showDocument !== car"
                                                (click)="toggleShowElements(car); $event.stopPropagation()">
                                            {{'SPAREPARTS_VIEW.DOCUMENTS' | translate}}
                                            <i class="fa fa-caret-down"></i>
                                        </button>
                                        <button class="base-btn servicePlanButton"
                                                *ngIf="cartService.showDocument === car || (location.path().includes('/raw-tools') && car?.hasRawTools)" routerLink="/cart"
                                                (click)="cartService.showDocument = undefined; maintenanceService.showPdf = false; $event.stopPropagation(); cartService.showDocumentWithLink = undefined;">
                                            {{'PROMPTS.BUTTONS.GO_BACK' | translate}}
                                            <i class="fa fa-times"></i>
                                        </button>
                                    </div>
                                    <div class="optionList">
                                        <ul class="elements" *ngIf="selectedCarPosition === car">
                                            <li (click)="showOption('info')" *ngIf="car.hasInfo">
                                                <i class="fa fa-file-pdf-o"></i>
                                                <span>{{'MAINTENANCE.MANUFACTURER_INFO' | translate}}</span>
                                            </li>
                                            <li (click)="showOption('specs')"
                                                *ngIf="userService.allow('MAINTENANCE-DATA')">
                                                <i class="fa fa-file-pdf-o"></i>
                                                <span>{{'MAINTENANCE.MANUFACTURER_SPEC' | translate}}</span>
                                            </li>
                                            <li (click)="showOption('change')"
                                                *ngIf="userService.allow('MAINTENANCE-DATA')">
                                                <i class="fa fa-file-pdf-o"></i>
                                                <span>{{'MAINTENANCE.CHANGE_INTERVAL' | translate}}</span>
                                            </li>
                                            <li (click)="showOption('serviceplan')" *ngIf="car.hasMaintenance">
                                                <i class="fa fa-file-pdf-o"></i>
                                                <span>{{'MAINTENANCE.SERVICEPLAN' | translate}}</span>
                                            </li>
                                            <li (click)="showOption('recall')">
                                                <i class="fa fa-file-pdf-o"></i>
                                                <span>{{'CAR_HISTORY.WARNING.TITLE' | translate}}</span>
                                            </li>
                                            <li (click)="showOption('tyre')">
                                                <i class="fa fa-file-pdf-o"></i>
                                                <span>{{'CART.TYRE_INFORMATION' | translate}}</span>
                                            </li>
                                            <li *ngIf="car.car.vin" (click)="showOption('cardetails')">
                                                <i class="fa fa-file-pdf-o"></i>
                                                <span>{{'CAR_DETAILS.EQUIPMENT.TITLE' | translate}}</span>
                                            </li>
                                            <li (click)="showOption('calculation')">
                                                <i class="fa fa-file-pdf-o"></i>
                                                <span>{{'MAINTENANCE.TECRMI_CALCULATION' | translate}}</span>
                                            </li>
                                            <li (click)="showOption('servicebook', car)"
                                                *ngIf="configService.isActiveTool('DIGITAL_SERVICEBOOK') && car?.serviceBook">
                                                <i class="fa fa-book"></i>
                                                <span>{{'CART.SERVICEBOOK' | translate}}</span>
                                            </li>
                                            <li (click)="showOption('document', car, document)"
                                                *ngFor="let document of car.documents">
                                                <i class="fa fa-file-pdf-o"></i>
                                                <span>{{document.text}}</span>
                                            </li>
                                            <li *ngIf="car?.hasRawTools"
                                                (click)="showOption('rawtool', car)">
                                                <i class="fa fa-wrench"></i>
                                                <span>{{'CART.RAWTOOL' | translate}}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <!-- order infos -->
                            <ng-container *ngIf="cartService.isOrder()">
                                <app-collapsible-panel [panelTitle]="supplier.key"
                                                       *ngFor="let supplier of cartService.currentCart.externalIds | cat">
                                    <div class="row">
                                        <div class="col-5">
                                            <span>{{'CART.ORDER_NUMBER' | translate}}</span>
                                        </div>
                                        <div class="col-7">
                                            <span>{{supplier.value}}</span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-5">
                                            <span>{{'CART.ACQUISITION_TYPE' | translate}}</span>
                                        </div>
                                        <div class="col-7">
                                            <span>{{'CART.ACQUISITION_TYPES.' + cartService.currentCart?.selectedAcquisitionTypes[supplier.key] | translate}}</span>
                                        </div>
                                    </div>
                                </app-collapsible-panel>
                            </ng-container>

                            <!-- calculation cart info -->

                            <app-collapsible-panel class="calculation-info"
                                                   *ngIf="cartService?.currentCart?.calculationData"
                                                   [folded]="false"
                                                   [panelTitle]="'CART.CALCULATION_DATA' | translate">
                                <div class="row">
                                    <div class="col-6">
                                        <span>{{'CART.CALCULATION_NAME' | translate}}</span>
                                    </div>
                                    <div class="col-6">
                                        <span *ngIf="cartService?.currentCart?.calculationData?.name">{{cartService?.currentCart?.calculationData?.name}}</span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6">
                                        <span>{{'CART.STATUS' | translate}}</span>
                                    </div>
                                    <div class="col-6">
                                        <span *ngIf="cartService?.currentCart?.calculationData?.approvalTransactionStatus">{{cartService?.currentCart?.calculationData?.approvalTransactionStatus}}</span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6">
                                        <span>{{'CART.REFERENCE' | translate}}</span>
                                    </div>
                                    <div class="col-6">
                                        <span *ngIf="cartService?.currentCart?.calculationData?.approvalReference">{{cartService?.currentCart?.calculationData?.approvalReference}}</span>
                                    </div>
                                </div>
                                <div class="row button-row"
                                     *ngIf="cartService?.currentCart?.predecessor?.length > 0 || cartService?.currentCart?.successor?.length > 0">
                                    <div class="col-4">
                                        <button class="base-btn"
                                                *ngIf="cartService?.currentCart?.predecessor?.length > 0"
                                                (click)="cartService.getCartContent(cartService.currentCart.predecessor)">
                                            <i class="fa fa-undo mr-2"></i>
                                            <span>{{'CART.UNDO' | translate}}</span>
                                        </button>
                                    </div>
                                    <div class="col-4 text-center">
                                        <button class="base-btn" (click)="cartService.selectCart(cartService?.currentCart?.id,
                                        false, cartService?.currentCart?.isLockedOrder)"
                                                *ngIf="!cartService?.isNewestCart()">
                                            <i class="fa fa-check mr-2"></i>
                                            <span>{{'CART.ACCEPT' | translate}}</span>
                                        </button>
                                    </div>
                                    <div class="col-4 text-right">
                                        <button class="base-btn" *ngIf="cartService?.currentCart?.successor?.length > 0"
                                                (click)="cartService.getCartContent(cartService.currentCart.successor)">
                                            <i class="fa fa-repeat mr-2"></i>
                                            <span>{{'CART.REDO' | translate}}</span>
                                        </button>
                                    </div>
                                </div>
                            </app-collapsible-panel>

                            <!-- fixprice positions -->

                            <app-collapsible-panel class="fixprice-positions"
                                                   *ngIf="cartService.currentCart?.fixedPrices?.length > 0"
                                                   [folded]="false"
                                                   [panelTitle]="'CART.FIXPRICE_POSITIONS' | translate">
                                <div class="row" *ngFor="let price of cartService.currentCart.fixedPrices">
                                    <div class="col-6">{{price?.description}}</div>
                                    <div class="col-4">{{price?.sellOutNetPerPieceFormatted}}</div>
                                    <div class="col-2">
                                        <app-checkbox [isChecked]="price?.posIdInCart > 0"
                                                      [isDisabled]="cartService.isOrder()"
                                                      (onChange)="toggleFixedPriceInCart(price, $event)">
                                        </app-checkbox>
                                    </div>
                                </div>
                            </app-collapsible-panel>
                        </ng-container>
                        <div *ngIf="cartService.previewCart" class="row">
                            <div class="col-12">
                                <div class="extended-order-details">
                                    <div class="order-details-header">
                                        {{'CART.ORDER_DETAILS' | translate}}
                                    </div>
                                    <div class="row ml-0">
                                        <div class="col-5"> {{'CART.DATE' | translate}}</div>
                                        <div class="col">{{cartService?.previewCart?.created | date: 'dd.MM.yyyy'}}
                                        </div>
                                    </div>
                                    <div class="row ml-0">
                                        <div class="col-5">{{ 'CART.REFERENCE' | translate }}</div>
                                        <div class="col">{{ cartService?.previewCart?.reference }}</div>
                                    </div>
                                    <div class="row ml-0">
                                        <div class="col-5">{{'CART.ORDER_NUMBER' | translate}}</div>
                                        <div class="col">{{cartService?.previewCart?.platformOrderId}}</div>
                                    </div>
                                    <div class="row ml-0">
                                        <div class="col-5">{{'CART.USERNAME' | translate}}</div>
                                        <div class="col">{{cartService?.previewCart?.loginName}}</div>
                                    </div>
                                    <div class="row ml-0" *ngIf="cartService?.previewCart.selectedAcquisitionTypes">
                                        <div class="order-details-header">
                                            {{'CART.DELIVERY_TYPE' | translate}}
                                        </div>
                                    </div>
                                    <ng-container
                                            *ngFor="let selectedAcquisitionType of cartService?.previewCart.selectedAcquisitionTypes  | keyvalue">
                                        <div class="row ml-0">
                                            <div class="col-5">{{selectedAcquisitionType.key}}</div>
                                            <div class="col">{{'CART.ACQUISITION_TYPES.' + selectedAcquisitionType.value | translate}}</div>
                                        </div>
                                    </ng-container>
                                    <div class="row ml-0" *ngIf="cartService?.previewCart.externalIds">
                                        <div class="order-details-header">
                                            {{'CART.EXTERNAL_ORDER_NUMBER' | translate}}
                                        </div>
                                    </div>
                                    <ng-container
                                            *ngFor="let external of cartService?.previewCart.externalIds  | keyvalue">
                                        <div class="row ml-0">
                                            <div class="col-5">{{external.key}}</div>
                                            <div class="col">{{external.value}}</div>
                                        </div>
                                    </ng-container>
                                    <div class="row ml-0 p-3 pr-5">
                                        <table class="simple-table">
                                            <thead>
                                            <tr>
                                                <th colspan="6" class="text-right"></th>
                                                <th colspan="6"
                                                    class="text-right">{{ 'CART.TOTAL_PRICE' | translate}}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td colspan="6" class="text-right">
                                                    {{ 'CART.SUM_NET' | translate }}
                                                </td>
                                                <td class="text-right" style="padding-right: 15px !important;"
                                                    *ngIf="userService.sale">
                                                    {{ cartService?.previewCart?.cartCostDTO.sellOutNetTotalFormatted}}
                                                </td>
                                                <td class="text-right" style="padding-right: 15px !important;"
                                                    *ngIf="!userService.sale">
                                                    {{ cartService?.previewCart?.cartCostDTO.sellInNetTotalFormatted}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="6" class="text-right">
                                                    {{ 'CART.VAT' | translate }} {{ cartService?.previewCart?.cartCostDTO.vat }}
                                                    %
                                                </td>
                                                <td class="text-right" style="padding-right: 15px !important;"
                                                    *ngIf="userService.sale">
                                                    {{ cartService?.previewCart?.cartCostDTO.sellOutVatTotalTotalFormatted}}
                                                </td>
                                                <td class="text-right" style="padding-right: 15px !important;"
                                                    *ngIf="!userService.sale">
                                                    {{ cartService?.previewCart?.cartCostDTO.sellInVatTotalFormatted}}
                                                </td>
                                            </tr>
                                            <tr *ngIf="hasDeliveryCosts(cartService?.previewCart) && !userService.sale">
                                                <td colspan="6"
                                                    class="text-right">{{'CART.DELIVERY_COSTS' | translate}}</td>
                                                <td></td>
                                            </tr>
                                            <ng-container *ngIf="!userService.sale">
                                                <tr *ngFor="let key of getDeliveryCostsKeys(cartService?.previewCart)">
                                                    <td colspan="6" class="text-right">
                                                        {{cartService?.previewCart?.supplierInformation[key].supplierName}}
                                                    </td>
                                                    <td class="text-right">
                                                        {{cartService?.previewCart?.cartCostDTO?.deliveryCosts[key]?.valueFormmatted}}
                                                    </td>
                                                </tr>
                                            </ng-container>
                                            <tr class="details-sum">
                                                <td colspan="6" class="text-right">
                                                    {{ 'CART.SUM' | translate }}
                                                </td>
                                                <td class="text-right" style="padding-right: 15px !important;"
                                                    *ngIf="userService.sale">
                                                    {{ cartService?.previewCart?.cartCostDTO.sellOutGrossTotalFormatted}}
                                                </td>
                                                <td class="text-right" style="padding-right: 15px !important;"
                                                    *ngIf="!userService.sale">
                                                    <span *ngIf="!hasDeliveryCosts(cartService?.previewCart)">
                                                        {{ cartService?.previewCart?.cartCostDTO.sellInGrossTotalFormatted}}
                                                    </span>
                                                    <span *ngIf="hasDeliveryCosts(cartService?.previewCart)">
                                                        {{ cartService?.previewCart?.cartCostDTO.sellInGrossTotalWithDeliveryCostsFormatted}}
                                                    </span>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ng-container *ngIf="cartService.isPreview()">
                            <a class="order-btn packing-slip text-center text-decoration-none"
                               href='/api/documents/packing/download/{{cartService.currentPreviewOrderId}}'
                               target="_blank"> <span>{{'CART.OPEN_PACKING_SLIP' | translate}}</span></a>
                            <button class="order-btn preview"
                                    style="margin: 1rem; margin-left: auto; margin-right: auto;"
                                    (click)="selectOrderCart()">
                                <span>{{'CART.MASTERVIEW.TO_MASTERVIEW' | translate}}</span>
                            </button>
                            <div class="preview-text">
                                <span>{{'CART.MASTERVIEW.EXPLANATION' | translate}}*</span>
                                <br><br>
                                <span class="small">*{{'CART.MASTERVIEW.NOTE' | translate}} </span>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="bottom-section"></div>
        </div>

        <div class="right-panel no-float" *ngIf="location.path().endsWith('/article-selection')">
            <div class="panel-content right-sidebar">
                <div class="inner-content">
                    <app-article-details [service]="cartService"
                                         *ngIf="cartService.selectedArticle || cartService.selectedPseudoArticle"></app-article-details>
                    <app-car-details
                            *ngIf="!cartService.selectedArticle && !cartService.selectedPseudoArticle"></app-car-details>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="close-cart-overlay" *ngIf="cartService.showCloseCartWarning"
     (click)="cartService.showCloseCartWarning = false">
    <div class="center-wrapper" (click)="stopPropagation($event)">
        <div class="header">
            <i class="fa fa-shopping-cart"></i>
            <span class="title">{{'CART.CLOSE_CART_BUTTON' | translate}}</span>
            <i class="fa fa-times" (click)="cartService.showCloseCartWarning = false"></i>
        </div>
        <div class="content text-center p-3">
            <span>{{'CART.CLOSE_CART_WARNING' | translate}}</span>
            <div class="button-row text-left">
                <button class="base-btn success continue mr-3"
                        (click)="closeCart()">{{'GENERAL.CONTINUE' | translate}}</button>
                <button class="base-btn abort"
                        (click)="cartService.showCloseCartWarning = false">{{'GENERAL.ABORT' | translate}}</button>
            </div>
        </div>
    </div>
</div>

<app-cart-supplier-change *ngIf="cartService.showSupplierSelection"></app-cart-supplier-change>
