import {Component, OnDestroy, OnInit} from '@angular/core';
import {fadeAnimation} from '../shared/animations/fade.animation';
import {ExternalNotificationService} from '../shared/services/external-notification.service';
import {UserService} from '../shared/services/user/user.service';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {CarService} from "../shared/services/car/car.service";
import {CartService} from "../cart/services/cart.service";
import {take} from "rxjs/operators";
import {NotificationService} from "../notification/notification.service";

declare var _: any;

@Component({
    selector: 'app-root',
    templateUrl: './root.component.html',
    styleUrls: ['./root.component.scss'],
    animations: [fadeAnimation]
})

export class RootComponent implements OnInit, OnDestroy {

    public userIsLoggedIn: boolean;
    public routeState: string;
    private subscriptions: Subscription = new Subscription();

    constructor(private externalNotificationService: ExternalNotificationService,
                private router: Router,
                private notificationService: NotificationService,
                private carService: CarService,
                private cartService: CartService,
                public userService: UserService) {
        this.router.events.subscribe(
            (event: any) => {
                if (event && event.url && (event.url !== this.routeState)) {
                    this.routeState = event.url.replace('/', '').replace('-', '_').toUpperCase();
                    this.checkUsage();
                }
            }
        )
    }

    ngOnInit() {
        this.subscriptions.add(
            this.userService.loginBehavior$.subscribe(res => {
                this.userIsLoggedIn = !!res;
            }));
        this.subscriptions.add(this.userService.accountData$.subscribe(response => {
            if (response) {
                this.externalNotificationService.getAllOwnNotifications();
                this.notificationService.connectAndSubscribe();
                this.subscriptions.add(this.notificationService.messages.subscribe({
                    next: (message) => {
                        this.externalNotificationService.handleNewMessages(message);
                    }
                }));
            }
        }));
        this.subscriptions.add(this.carService.changeCart$.subscribe(changeCart => {
            if (changeCart) {
                this.cartService.createCart();
            }
        }));
    }

    ngOnDestroy() {
        this.userService.accountData$.next(undefined);
        this.subscriptions.unsubscribe();
        this.notificationService.disconnect();
    }

    // check current url and update usage counter to track user behavior
    private checkUsage = (() => {
        let timeout: ReturnType<typeof setTimeout>;
        return () => {
            timeout = setTimeout(() => {
                let usage: string;
                switch (this.routeState) {
                    case 'ARTICLES':
                        usage = 'ARTICLE';
                        break;
                    case 'TECHNICAL_DATA':
                        usage = 'TECHNICAL_DATA';
                        break;
                    case 'MAINTENANCE':
                        usage = 'MAINTENANCE';
                        break;
                    case 'WORK':
                        usage = 'WORKS';
                        break;
                    case 'GRAPHICAL_PART_SEARCH':
                        usage = 'GRAPHICAL_PART_SEARCH';
                        break;
                    case 'TYRE':
                        usage = 'TYRES';
                        break;
                }

                if (!!usage) {
                    this.userService.setUsageRest(usage).pipe(take(1)).subscribe();
                }
                clearTimeout(timeout);
            }, 250);
        };
    })();
}
