<div class="work-data" [ngClass]="{'isPagination': !userService.enableInfiniteScroll}">
    <div class="content clean sidebar-opener-wrapper" *ngIf="(stateService.workContent | async) === 'CONTENT'">
        <div class="sidebar-opener" [ngClass]="userService?.getNavigation()">
            <ul>
                <li (click)="showNavigation()">
                    {{ 'GENERAL.NAVIGATION.NAVIGATION' | translate }}
                </li>
                <li (click)="showFilter()" [ngClass]="{'disabled':!hasFilter()}">
                    {{ 'GENERAL.NAVIGATION.FILTER' | translate }}
                </li>
                <li (click)="userService?.toggleNavigation()">
                    <i class="fa fa-chevron-right"></i>
                </li>
            </ul>
        </div>
        <app-default-loading-page *ngIf="loading()"></app-default-loading-page>
        <app-message [title]="'PROMPTS.MESSAGE.DELETE_MAINTENANCE_TITLE' | translate" [textFirst]="'PROMPTS.MESSAGE.DELETE_MAINTENANCE_TEXT_FIRST' | translate"
                     [textSecond]="'PROMPTS.MESSAGE.DELETE_MAINTENANCE_TEXT_SECOND' | translate"
                     [btn]="['PROMPTS.BUTTONS.CANCEL' | translate, 'PROMPTS.BUTTONS.NO' | translate, 'PROMPTS.BUTTONS.YES' | translate]"
                     (onBtnClick)="workService.onMessageBtnClick($event)"
                     (onModalClose)="workService.showDependencyError = false"
                     *ngIf="workService.showDependencyError">
        </app-message>
        <div class="left-panel no-float" [ngClass]="userService?.getNavigation()">
            <div class="panel-content left-sidebar">
                <div class="inner-content">
                    <ngb-tabset id="tabset" #t6>

                        <!-- Arbeitswerte -->
                        <ngb-tab title="{{'CLUB_SETTINGS.NAVIGATION.WORK' | translate}}">
                            <ng-template ngbTabContent>
                                <div class="padding-top">
                                    <ng-container>
                                        <div class="toggle" *ngIf="work && work.length > 1 && selectedQualifier">
                                            <app-toggle-switch [textLeft]="work[0].displayName" [textRight]="work[1].displayName"
                                                               (onChange)="setWorkQualifier()"></app-toggle-switch>
                                        </div>

                                        <ng-container *ngFor="let qualifier of work">
                                            <ul class="custom-tree" *ngIf="qualifier.displayName === selectedQualifier">
                                                <ng-container
                                                        *ngFor="let group of qualifier.groups | cat">
                                                    <li class="list"
                                                        [ngClass]="{'selected' : group.value == selectedGroup}">
                                                        <div [ngClass]="{'active' : group.value == selectedGroup}">
                                                            <i class="folder fa fa-circle"
                                                               [ngClass]="{'open' : group.value == selectedGroup}"
                                                               (click)="setWorkGroup(group.value); searchString = ''; resetSearchBox(searchBox)"></i>
                                                            <span (click)="setWorkGroup(group.value); searchString = ''; resetSearchBox(searchBox)">{{ group.value.name}}</span>
                                                        </div>
                                                        <ul *ngIf="group.value == selectedGroup">
                                                            <li class="list"
                                                                *ngFor="let subGroup of group.value.subGroups | cat"
                                                                (click)="setWorkItems(subGroup.value,subGroup.value.name); searchString = '';">
                                                                <div [ngClass]="{'active' : subGroup.value.name === selectedItem}">
                                                                    <i class="fa fa-circle"
                                                                       [ngClass]="{'open' : subGroup.value.name === selectedItem}"></i>
                                                                    <span>{{subGroup.value.name}}</span>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ng-container>
                                            </ul>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </ng-template>
                        </ngb-tab>

                        <!-- Filter -->
                        <ngb-tab [disabled]="!(workService?.searchResult) || router.url.indexOf('/article') < 0">
                            <ng-template ngbTabTitle>
                                <div [@bounceIn]="workService?.searchResult?.possibleFilters?.length > 0 && router.url.indexOf('/article') > -1">
                                    <i class="fa fa-filter"
                                       *ngIf="workService?.searchResult?.possibleFilters?.length > 0 && router.url.indexOf('/article') > -1"></i>
                                    <span style="margin-left: 5px;">{{'SPAREPARTS_VIEW.FILTER' | translate}}</span>
                                    <span *ngIf="workService?.searchResult?.possibleFilters?.length > 0 && router.url.indexOf('/article') > -1">(<span>{{workService?.searchResult?.possibleFilters?.length}}</span>)</span>
                                </div>
                            </ng-template>
                            <ng-template ngbTabContent>
                                <div class="button-header">
                                    <button class="base-btn" [disabled]="workService.articlesLoading || workService.infinityLoading" (click)="workService.updateFilter()">
                                        {{'GENERAL.UPDATE' | translate}}
                                    </button>
                                    <button class="base-btn ml-3" *ngIf="!configService.getAccountDataSettingActive('settings', 'DEFAULT_SEARCH_FOR_ALL_ARTICLES')"
                                            (click)="workService.showAllArticles()">
                                        {{'SPAREPARTS_VIEW.SHOW_ALL' | translate}}
                                    </button>
                                    <button class="base-btn ml-3" *ngIf="workService?.searchResult?.possibleFilters?.length > 0 || workService?.searchResult?.searchForAllArticles"
                                            (click)="workService.resetFilter()">
                                        {{'SPAREPARTS_VIEW.FILTER_RESET' | translate}}
                                    </button>
                                </div>
                                <div class="scroll-content">
                                <ng-container
                                        *ngFor="let groups of workService?.searchResult?.possibleFilters">
                                    <app-collapsible-panel
                                            [folded]="workService?.searchResult?.possibleFilters.length !== 1 && !(workService.foldedCollapsePanel.indexOf(groups.groupName) > -1)"
                                            (onChangeFolded)="workService.toggleFolded($event, groups.groupName)"
                                            [panelTitle]="groups.groupName | translate"
                                            *ngIf="groups.groupId === 'BRAND'">
                                        <div class="filterVar" *ngFor="let filter of groups.filters">
                                            <app-checkbox *ngIf="!filter.fixedFilter"
                                                          [isChecked]="workService.findFilter(filter, true)"
                                                          [labelText]="filter.displayValue"
                                                          (onChange)="workService.toggleActiveFilter(filter)"></app-checkbox>
                                            <app-checkbox *ngIf="filter.fixedFilter"
                                                          [isChecked]="workService.findFilter(filter, true)"
                                                          [labelText]="filter.displayValue"
                                                          [isDisabled]="true">
                                            </app-checkbox>
                                        </div>
                                    </app-collapsible-panel>
                                </ng-container>

                                <ng-container
                                        *ngFor="let groups of workService?.searchResult?.possibleFilters">
                                    <app-collapsible-panel
                                            [folded]="!(workService.foldedCollapsePanel.indexOf(groups.groupName) > -1)"
                                            (onChangeFolded)="workService.toggleFolded($event, groups.groupName)"
                                            [panelTitle]="groups.groupName"
                                            *ngIf="groups.groupId !== 'BRAND'">
                                        <div class="filterVar" *ngFor="let filter of groups.filters">
                                            <app-checkbox *ngIf="!filter.fixedFilter"
                                                          [isChecked]="workService.findFilter(filter, true)"
                                                          [labelText]="filter.displayValue"
                                                          (onChange)="workService.toggleActiveFilter(filter)">
                                            </app-checkbox>
                                            <app-checkbox *ngIf="filter.fixedFilter"
                                                          [isChecked]="workService.findFilter(filter, true)"
                                                          [labelText]="filter.displayValue"
                                                          [isDisabled]="true">
                                            </app-checkbox>
                                        </div>
                                    </app-collapsible-panel>
                                </ng-container>
                                </div>
                            </ng-template>
                        </ngb-tab>
                    </ngb-tabset>

                </div>
            </div>
        </div>
        <div class="center-panel no-float" [ngClass]="userService?.getNavigation()">
            <div class="heading blue">
                <ng-container *ngIf="route?.snapshot.data['state'] != 'article'">

                    <span class="clickable" (click)="reset()">
                        <i class="fa fa-th" aria-hidden="true"></i> {{ 'CLUB_SETTINGS.NAVIGATION.WORKING_VALUES' | translate }}
                    </span>
                    <span *ngIf="selectedGroup" (click)="workService.selectedItems = undefined; selectedItem = undefined; workService.showDocumentWithLink = undefined;" class="clickable">
                         <i class="fa fa-caret-right"></i> {{selectedGroup.name}}
                    </span>
                    <span *ngIf="selectedItem" (click)="_selectedItem = undefined; workService.showDocumentWithLink = undefined;" class="clickable">
                       <i class="fa fa-caret-right"></i>  {{selectedItem}}
                    </span>
                </ng-container>

                <ng-container *ngIf="route?.snapshot.data['state'] === 'article'">
                   <span class="clickable" routerLink="{{location.path().replace('/article','')}}" (click)="workService.selectedOption = ''; workService.showDocumentWithLink = undefined">
                      <i class="fa fa-th" aria-hidden="true"></i>
                    </span>
                    <span routerLink="{{location.path().replace('/article','')}}"
                          class="clickable">
                        {{'CLUB_SETTINGS.NAVIGATION.WORKING_VALUES' | translate}}
                    </span>
                    <span *ngIf="route?.snapshot.data['state'] === 'article'"
                          class="clickable">
                        <i class="fa fa-caret-right"></i>
                        {{'GENERAL.ARTICLE' | translate}}
                    </span>
                </ng-container>


                    <div class="articleSizeWrapper d-inline-block float-right" *ngIf="location.path().endsWith('/work/article')">
                        <div class="articleSize" *ngIf="workService?.searchResult?.items"
                             (click)="showArticleSize = !showArticleSize">
                            <i class="fa fa-list"></i>
                            <span class="mr-2" *ngIf="articlesService.selectedSearchResultSize">
                                {{'GENERAL.' + articlesService.selectedSearchResultSize.toUpperCase() | translate}}
                            </span>
                            <span clasS="mr-2" *ngIf="!articlesService.selectedSearchResultSize">
                                {{'GENERAL.PLEASE_SELECT' | translate | ellipsis: 10}}
                            </span>
                        </div>
                        <div class="dropdown-list" *ngIf="showArticleSize">
                            <ul>
                                <li (click)="setArticleSize('big')">
                                    <span>{{'GENERAL.BIG' | translate}}</span>
                                </li>
                                <li (click)="setArticleSize('normal')">
                                    <span>{{'GENERAL.NORMAL' | translate}}</span>
                                </li>
                                <li (click)="setArticleSize('small')">
                                    <span>{{'GENERAL.SMALL' | translate}}</span>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="searchResultSupplierWrapper d-inline-block float-right" *ngIf="location.path().endsWith('/work/article')">
                        <div class="searchResultSupplier"
                             *ngIf="userService?.suppliers?.length > 0 && articlesService.selectedSearchResultSupplier
                             && workService.searchResult?.items"
                             (click)="showSearchResultSupplier = !showSearchResultSupplier">
                            <i class="fa fa-truck"></i>
                            <!-- AFTER MARKET -->
                            <span class="mr-2" *ngIf="workService.selectedSearchResultSupplier">
                                  {{helperService.returnSupplierName(articlesService.selectedSearchResultSupplier, userService.getRegularSuppliersName(userService.suppliers)) | ellipsis: 10}}
                            </span>
                            <span class="mr-2" *ngIf="!workService.selectedSearchResultSupplier">
                                {{'GENERAL.PLEASE_SELECT' | translate | ellipsis: 10}}
                            </span>
                        </div>
                        <div class="dropdown-list" *ngIf="showSearchResultSupplier">
                            <!-- AFTER MARKET -->
                            <ng-container *ngIf="articlesService.supplierType === 'AFTER_MARKET'">
                                <ul>
                                    <ng-container *ngFor="let supp of userService.getRegularSuppliersName(userService.suppliers)">
                                        <li *ngIf="supp !== workService.selectedSearchResultSupplier"
                                            (click)="setSearchResultSupplier('AFTER_MARKET', supp.code)">
                                            <span>{{supp.name}}</span>
                                        </li>
                                    </ng-container>
                                </ul>
                            </ng-container>
                        </div>
                    </div>


                <span class="pull-right search-bar" *ngIf="location.path().endsWith('/work') && !workService?.showDocumentWithLink">

                    <form class="main-search-form show-search" action="">
                        <input type="text"
                               #workSearchInput
                               class="search-input form-control"
                               placeholder="{{ 'SPAREPARTS_VIEW.SEARCH_FOR_GENART' | translate }}"
                               [(ngModel)]="searchString"
                               (click)="workSearch()"
                               (keyup)="onWorkSearch($event)"
                               name="searchString"/>
                      </form>

                    <i id="search-open" (click)="searchOpen = !searchOpen; focusInput(workSearchInput); reset()"
                       *ngIf="!searchOpen"><i class="fa fa-search"></i></i>
                    <i id="search-close" class="fa fa-times" (click)="searchOpen = !searchOpen;"
                       *ngIf="searchOpen"></i>
                </span>
                <span *ngIf="workService?.showDocumentWithLink">

                    <i class="fa fa-caret-right"></i>
                    <span>{{workService?.showDocumentWithLink?.text}}</span>

                    <button class="print-btn pull-right" (click)="printIframe('work-manual')">
                        <i class="fa fa-print"></i>
                        <span>{{'PRINT_VIEW.PRINT' | translate}}</span>
                    </button>

                    <button class="print-btn pull-right" (click)="workService.showDocumentWithLink = undefined">
                        <i class="fa fa-times"></i>
                        <span>{{'GENERAL.BACK' | translate }}</span>
                    </button>
                </span>
            </div>

            <div class="panel-content center-content white-background" *ngIf="workService?.showDocumentWithLink">
                <iframe width="100%" onLoad="this.style.height=(this.contentDocument.body.scrollHeight + 30) + 'px';" [src]="workService?.showDocumentWithLink?.url | iFrame" id="work-manual"></iframe>
            </div>

            <div class="panel-content center-content white-background" *ngIf="!workService?.showDocumentWithLink" [ngClass]="{'ovh': searchOpen}" appInfiniteScroller (onScrollEnd)="workService.loadNext($event)">

                <div class="flex-container" [ngClass]="{'noScrollButton': !showScrollButton, 'articleView': route?.snapshot.data['state'] === 'article', 'browserIsIE': helperService.browserIsIE()}">
                    <div class="static-content w-100" *ngIf="workService?.articleCount > 0 || searchString?.length > 0">
                        <ng-container *ngIf="workService?.articleCount > 0">
                            <app-work-header></app-work-header>
                            <ng-container *ngIf="route?.snapshot.data['state'] === 'article'">
                                <app-tabs [service]="workService" [articleList]="workService.articleList" (showScrollButtonEmitter)="toggleShowScrollButton($event)"></app-tabs>
                                <app-filter [service]="workService"></app-filter>
                            </ng-container>
                        </ng-container>

                        <app-search-box #searchBox (_onSearch)="searchItemSelected($event)" [(searchOpen)]="searchOpen"
                                        [searchString]="searchString"
                                        [isWork]="true"
                                        [searchIn]="work"></app-search-box>
                    </div>

                    <div class="scroll-content" appInfiniteScroller (onScrollEnd)="workService.loadNext($event)" [ngClass]="{'hasArticles': workService?.articleCount > 0}" *ngIf="!searchOpen">
                        <ng-container *ngIf="route?.snapshot.data['state'] === 'article'">
                            <app-default-loading-page *ngIf="workService.loadWorkList || workService.articlesLoading"></app-default-loading-page>
                            <app-articles-search-result [service]="workService" *ngIf="workService?.searchResult?.items?.length > 0"></app-articles-search-result>
                            <app-no-results *ngIf="!workService?.articlesLoading && workService?.searchResult?.items?.length === 0"></app-no-results>
                        </ng-container>


                        <ng-container *ngIf="route?.snapshot.data['state'] != 'article'">
                            <ng-container *ngIf="work && work?.length > 1 && !selectedQualifier ">
                                <div class="assembly-categories" [ngClass]="{'move': searchOpen}" [@catAnim]="''">
                                    <div class="assembly-category"
                                         *ngFor="let qualifier of work"
                                         [ngClass]="{'selected' : qualifier.displayName === selectedQualifier}"
                                         (click)="setWorkQualifier(qualifier.displayName)">
                                        <div class="first"></div>
                                        <div class="name-wrapper-no-image">
                                            {{qualifier.displayName}}
                                        </div>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container
                                    *ngIf="!workService.selectedItems && (selectedQualifier || ( work && work.length === 1 )) && !selectedGroup">
                                <div class="search-box-swing  assembly-categories" [ngClass]="{'move': searchOpen}"
                                     [@catAnim]="''">
                                    <ng-container *ngFor="let qualifier of work">
                                        <ng-container *ngIf="qualifier.displayName === selectedQualifier">
                                            <ng-container *ngIf="!selectedGroup">
                                                <div class="assembly-category"
                                                     *ngFor="let group of qualifier.groups | cat"
                                                     [ngClass]="{'selected' : group.value == selectedGroup}"
                                                     (click)="setWorkGroup(group.value); searchString = ''; resetSearchBoxSearchResult()">
                                                    <div class="second"></div>
                                                    <div class="name-wrapper-no-image">
                                                        {{ group.value.name}}
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="selectedGroup && !workService.selectedItems">
                                <div class="search-box-swing assembly-categories"
                                     [ngClass]="{'move': searchOpen}" [@catAnim]="''">
                                    <div class="assembly-category"
                                         *ngFor="let subGroup of selectedGroup.subGroups | cat"
                                         (click)="setWorkItems(subGroup.value, subGroup.value.name); searchString = ''; resetSearchBoxSearchResult()">
                                        <div class="third"></div>
                                        <div class="name-wrapper-no-image">
                                            {{subGroup.value.name}}
                                        </div>
                                    </div>
                                </div>
                            </ng-container>

                            <div class="search-box-swing" [ngClass]="{'move': searchOpen}" *ngIf="workService.selectedItems">
                                <div class="work_item_title">
                                    <h1 *ngIf="selectedGroup">
                                        <strong>{{selectedGroup.name}}</strong>
                                        <span *ngIf="selectedItem">
                                    {{selectedItem}}
                                </span>
                                    </h1>
                                </div>

                                <div class="searchFilter" *ngIf="searchString?.length > 0">
                                    <app-chip [text]="searchString" (onChipClick)="resetSearchFilter()" (onIconClick)="resetSearchFilter()"></app-chip>
                                    <app-work-item [items]="workService.selectedItems" (onItemSelect)="itemSelected($event)"></app-work-item>
                                </div>


                                <app-work-item *ngIf="!searchString" [items]="workService.selectedItems" (onItemSelect)="itemSelected($event)"></app-work-item>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>



        </div>
        <div class="right-panel no-float" [ngClass]="userService?.getNavigation()"
             [@sidebar]="( workService.expanded_right ? 'in': 'out' )">
            <div class="panel-content right-sidebar">
                <div class="inner-content">
                    <ng-container *ngIf="router.url.indexOf('/article') < 0">
                        <app-car-details
                                *ngIf="!workService.selectedItem && !workService?.selectedOption"></app-car-details>
                        <app-work-details *ngIf="workService.selectedItem" [service]="workService"></app-work-details>
                    </ng-container>

                    <ng-container *ngIf="router.url.indexOf('/article') > -1">
                        <app-article-details [showBelongingWork]="false" [showArtikelLists]="false" [service]="workService" *ngIf="workService?.selectedArticle"></app-article-details>
                        <app-car-details *ngIf="!workService?.selectedArticle"></app-car-details>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>

    <app-content-overlay [showContent]="stateService.workContent | async" [newRoute]="'/work'"></app-content-overlay>

    <ng-container *ngIf="workService.showGallery">
        <app-gallery [title]="workService.selectedArticle.description" (closeGallery)="workService.showGallery = false" [documentList]="workService.filterImages()" [selectedImage]="workService.selectedImage"></app-gallery>
    </ng-container>

</div>
