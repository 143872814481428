<div class="setting-list">

    <div class="club-setting-title">
        <h1>
            <strong>
                {{'CLUB_ADMIN.SETTING_NAME.' + settingName | translate}}
            </strong>
        </h1>
    </div>

    <ng-container *ngIf="isTabbedSetting()">
        <ngb-tabset class="setting-tabset" (tabChange)="resetSettingDetailsSelection()">
            <ngb-tab *ngFor="let tab of getTabNames()" title="{{'GENERAL.MODE.' + tab | translate}}">
                <ng-template ngbTabContent>
                    <app-dynamic-setting-drag-list [settingList]="getSettingListByTabName(tab)" [settingName]="settingName"></app-dynamic-setting-drag-list>
                </ng-template>
            </ngb-tab>
        </ngb-tabset>
    </ng-container>
    <ng-container *ngIf="!isTabbedSetting()">
        <app-dynamic-setting-drag-list [settingList]="settingList" [settingName]="settingName"></app-dynamic-setting-drag-list>
    </ng-container>


<!--<<<<<<< HEAD-->
<!--        <ng-container *ngFor="let setting of settingList">-->
<!--            <div class="row drag-box"-->
<!--                 [ngClass]="{'clickable': configService.isSelectableSetting(setting)}" cdkDrag-->
<!--                 (click)="configService.setSelectedSetting(setting)">-->
<!--                <div class="col">-->
<!--                    <span class="setting-name"-->
<!--                          *ngIf="settingName !== 'QUICKLINKS'">{{'CLUB_SETTINGS.' + settingName + '.' + setting.name | translate}}</span>-->
<!--                    <span class="setting-name"-->
<!--                          *ngIf="settingName === 'QUICKLINKS'">{{getTranslation(setting.name)}}</span>-->
<!--                    <span class="multiple-settings-icon" *ngIf="configService.isSelectableSetting(setting)">-->
<!--          <i class="fa fa-cog" [ngClass]="{'active': configService.selectedSetting === setting}"></i>-->
<!--        </span>-->
<!--                    <app-checkbox [isChecked]="setting.active" (onChange)="changeActive(setting, $event)"-->
<!--                                  [labelText]="'GENERAL.ACTIVE' | translate">-->
<!--                    </app-checkbox>-->
<!--                </div>-->
<!--                <div class="col-12 club-logo" *ngIf="setting.name === 'USE_CLUB_LOGO'-->
<!--                && configService.getActiveForName('EXTENDED_SETTINGS','USE_CLUB_LOGO')">-->
<!--                    <div class="row">-->
<!--                        <app-checkbox [labelText]="'CLUB_SETTINGS.EXTENDED_SETTINGS.GLOBAL_FIRM_LOGO' | translate"-->
<!--                                      [isChecked]="configService.getActiveForName('EXTENDED_SETTINGS','GLOBAL_FIRM_LOGO')"-->
<!--                                      (onChange)="changeActive(configService.getSubSettingByName('EXTENDED_SETTINGS', 'GLOBAL_FIRM_LOGO'), $event)">-->
<!--                        </app-checkbox>-->
<!--                    </div>-->
<!--                    <div class="row spacer">-->
<!--                        <div class="col-12">-->
<!--                            <div class="row">-->
<!--                                <div class="col" style="padding: 0 30px 0 30px">-->
<!--                                    <app-image-cropper *ngIf="imgUrl" [src]="imgUrl" [parent]="'config'"></app-image-cropper>-->
<!--                                </div>-->

<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="row" style="margin: 0">-->
<!--                        <div class="col-6">-->
<!--                            <form [formGroup]="form" (ngSubmit)="configService.saveClubConfig()">-->
<!--                                <div class="form-group">-->
<!--                                    <input type="file" id="avatar" accept="image/png, image/jpeg" (change)="onFileChange($event)" class="file-input" #fileInput>-->
<!--                                </div>-->
<!--                                {{'USER_PROFILE.LOGO_SIZE' | translate}} <br/><br/>-->
<!--                            </form>-->
<!--                        </div>-->
<!--                        <div class="col-6" *ngIf="configService.getValueForName('EXTENDED_SETTINGS', 'GLOBAL_FIRM_LOGO')?.length > 0">-->
<!--                            <img class="club-logo-img" id="currentLogo" [src]="configService.getValueForName('EXTENDED_SETTINGS', 'GLOBAL_FIRM_LOGO')">-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </ng-container>-->


<!--    </div>-->

<!--=======-->
<!--&gt;>>>>>> master-->
    <div class="button-row" *ngIf="configService.hasUnsavedSettings(settingName)">
        <button class="base-btn success" (click)="configService.saveClubConfig()">
            <i class="fa fa-check mr-2"></i>
            <span>{{'GENERAL.SAVE_CHANGES' | translate}}</span>
        </button>
    </div>
</div>
