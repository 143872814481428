<div class="header">
    <header class="hidden-xs">
        <div class="top-header">
            <div class="left-header">
                <div class="main-nav d-none d-xl-block">
                    <ul>
                        <li routerLink="/">
                            <div class="product-logo {{userService?.accountData?.language}} {{cartService.getCalculation()}} {{userService?.accountData?.country}}"></div>
                        </li>
                        <li routerLink="/" >
                            <i class="fa fa-home"></i>
                        </li>
                        <ng-container appSortable *ngFor="let tab of configService.getSettingByName('NAVIGATION')?.settings">
                            <li routerLink="{{tab.link}}" routerLinkActive="active" *ngIf="tab.active">
                                <span *ngIf="tab.name !== 'PARTNER' || !(partnerService.getPartnerDisplayName()?.length > 0)">{{'CLUB_SETTINGS.NAVIGATION.' + tab.name | translate}}</span>
                                <span *ngIf="tab.name === 'PARTNER' && partnerService.getPartnerDisplayName()?.length > 0">{{partnerService.getPartnerDisplayName()}}</span>
                            </li>
                        </ng-container>
                    </ul>
                </div>
                <div class="main-nav d-xl-none">
                    <ul>
                        <li routerLink="/">
                            <div class="product-logo {{userService?.accountData?.language}} {{cartService.getCalculation()}} {{userService?.accountData?.country}}"></div>
                        </li>
                        <li (click)="burgerOpen = !burgerOpen" [ngClass]="{'active': burgerOpen}">
                            <div id="hamburger-button" [ngClass]="{'open': burgerOpen}">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="burger-wrapper" *ngIf="burgerOpen" (click)="burgerOpen = false"></div>
                <div class="burger-nav d-xl-none" [ngClass]="{'open': burgerOpen}">
                    <ul (click)="burgerOpen = !burgerOpen" [ngClass]="{'hidden': !burgerOpen}">
                        <li routerLink="/" >
                            <i class="fa fa-home"></i>
                        </li>
                        <ng-container appSortable *ngFor="let tab of configService.getSettingByName('NAVIGATION')?.settings">
                            <li routerLink="{{tab.link}}" *ngIf="tab.active">
                                <span *ngIf="tab.name !== 'PARTNER' || !(partnerService.getPartnerDisplayName()?.length > 0)">{{'CLUB_SETTINGS.NAVIGATION.' + tab.name | translate}}</span>
                                <span *ngIf="tab.name === 'PARTNER' && partnerService.getPartnerDisplayName()?.length > 0">{{partnerService.getPartnerDisplayName()}}</span>
                            </li>
                        </ng-container>
                    </ul>
                    <div class="cooperation-image"></div>
                </div>
            </div>

            <div class="right-header">



                <button class="cart-btn header" routerLink="/cart">
                    <i class="fa fa-shopping-cart" *ngIf="!cartService.isOrder()"></i>
                    <i class="fa fa-clipboard" *ngIf="cartService.isOrder()"></i>
                    <span class="hidden-xs hidden-sm">{{ 'HEADER.CART' | translate }}</span>
                    <div class="count" *ngIf="!cartService.isOrder()">{{ cartService?.cartCount?.positionCount }}</div>
                    <div class="count" *ngIf="cartService.isOrder()"></div>
                </button>

                <app-user-menu></app-user-menu>

                <app-toggle-switch *ngIf="userService.allow('SELLIN')" [textLeft]="'HEADER.BUY_MODE' | translate" [textRight]="'HEADER.SALE_MODE' | translate" (onChange)="userService.setSale()" [isChecked]="userService.sale"></app-toggle-switch>

                <div class="cooperation-image"></div>
            </div>
        </div>

    </header>
    <div class="top-bar">
        <div class="top-bar-wrapper">
            <div class="pull-left">
                <app-car-history></app-car-history>
            </div>
            <button class="base-btn master-view" routerlink="/cart" *ngIf="cartService.isOrder()" (click)="cartService.switchToCart()">{{'CART.MASTERVIEW.CLOSE_MASTERVIEW' | translate}}</button>
            <div class="pull-right">
                <app-direct-search class="pull-right"></app-direct-search>
            </div>
        </div>
    </div>
</div>
