<div class="club-admin-dashboard">
    <div class="heading blue">
        <span class="clickable" (click)="adminService.club = undefined">
            <i class="fa fa-th" aria-hidden="true"></i>
        </span>
        <span *ngIf="adminService?.club || !adminService?.clubs">{{'CLUB_ADMIN.SEARCH' | translate}}</span>
        <span *ngIf="!adminService?.club && adminService?.clubs">{{'CLUB_ADMIN.SELECT_CLUB' | translate}}</span>
        <div class="float-right">
    <span class="clickable" (click)="newFirm()" *ngIf="adminService?.club || !adminService.clubs">
        <i class="fa fa-plus"></i>
        {{'ADMIN_PANEL.CREATE_FIRM' | translate}}
    </span>
        </div>
    </div>
    <div class="panel-content center-content white-background">
        <ng-container *ngIf="userService.allow('CLUB-ADMIN')">
            <ng-container *ngIf="adminService?.club || userService.accountData.clubId > -1">
                <div class="header-title">
                    <strong><span>{{adminService?.club?.name}}</span></strong>
                </div>

                <div class="search-field">
                    <app-material-form-input [type]="'text'" [label]="'TYRE.SEARCHTERM' | translate" [text]="searchTerm"
                                             (textChange)="searchFirm($event, 0, pageSize, true, this.currentClubId)"></app-material-form-input>
                    <i class="fa fa-search clickable"
                       (click)="searchFirm(searchTerm, 0, pageSize, true, this.currentClubId)"></i>
                    <i class="fa fa-close clickable" (click)="searchFirm('', 0, pageSize, true, this.currentClubId)"
                       *ngIf="search"></i>
                </div>
                <br>
            </ng-container>

            <div class="table-wrapper" *ngIf="adminService?.club || !adminService?.clubs">
                <app-default-loading-page *ngIf="loadFirm"></app-default-loading-page>
                <ng-container *ngIf="(firmData?.firmList?.length > 0) && !loadFirm">
                    <div class="row table-header">
                        <div class="col-2">
                            {{'ADMIN_PANEL.FIRMNAME' | translate}}
                        </div>
                        <div class="col-3">
                            {{'ADMIN_PANEL.MAIL' | translate}}
                        </div>
                        <div class="col">
                            {{'ADMIN_PANEL.RIGHTS' | translate}}
                        </div>
                        <div class="col-1 text-center">
                            {{'ADMIN_PANEL.ACTIVE' | translate}}
                        </div>
                        <div class="col-1 text-center">
                            {{'ADMIN_PANEL.OPTIONS' | translate}}
                        </div>
                        <div class="col-1 text-center" *ngIf="adminService.GlobalAdmin()">
                            {{'ADMIN_PANEL.DELETE' | translate}}
                        </div>
                    </div>
                    <div class="table-body">
                        <div class="row table-body-row" *ngFor="let firm of firmData.firmList">
                            <div class="col-2 align-self-center">{{firm.name}}</div>
                            <div class="col-3 align-self-center">{{firm.email}}</div>
                            <div class="col align-self-center">
                                <span *ngFor="let role of firm.roles; let last = last">
                                {{'ADMIN.ROLES.' + role | translate}}
                                    <span *ngIf="!last">, </span></span>
                            </div>
                            <div class="col-1 text-center align-self-center">
                                <ng-container [ngSwitch]="firm.active">
                                    <ng-container *ngSwitchCase="true">
                                        <i class="fa fa-check"></i>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="false">
                                        <i class="fa fa-close pr-0"></i>
                                    </ng-container>
                                </ng-container>
                            </div>
                            <div class="col-1 text-center align-self-center">
                                <button class="icon-btn" (click)="selectedFirm(firm)">
                                    <i class="fa fa-pencil"></i>
                                </button>
                            </div>
                            <div class="col-1 text-center align-self-center" *ngIf="adminService.GlobalAdmin()">
                                <button class="icon-btn" (click)="deletFirm(firm, true)">
                                    <i class="fa fa-trash"></i>
                                </button>
                            </div>
                            <div class="alert alert-danger col-12 align-self-center" *ngIf="firm.delete">
                                <b>{{firm.name}}</b> {{'GENERAL.DELETE' | translate}} <br/><br/>
                                <button class="base-btn" (click)="firm.delete = false;">
                                    {{'PROMPTS.BUTTONS.CANCEL' | translate}}
                                </button>
                                <button class="base-btn error pull-right no-top-margin" (click)="deletFirm(firm, true)">
                                    {{'ADMIN_PANEL.DELETE' | translate}}
                                </button>
                            </div>
                        </div>
                    </div>
                    <span *ngIf="lastSide()"
                          class="centerItem">{{'SPAREPARTS_VIEW.SEARCH_RESULTS.END' | translate}}</span>
                </ng-container>
                <ng-container *ngIf="search && firmData?.firmList?.length <= 0">
                    <div class="no-result">
                        <app-no-results></app-no-results>
                    </div>
                </ng-container>
                <ng-container *ngIf="errorHandlerService.newError">
                    <app-error-handler></app-error-handler>
                </ng-container>
            </div>

            <ng-container *ngIf="!adminService?.club && userService.allow('GLOBAL-ADMIN')">
                <div class="assembly-categories">
                    <div class="assembly-category" *ngFor="let club of adminService?.clubs"
                         (click)="adminService.changeClub(club)">
                        <div class="name-wrapper-no-image">
                            {{club.name}}
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </div>
    <div class="paginationBar" *ngIf="true">
        <app-pagination (goPage)="searchFirm(searchTerm, $event -1, firmData?.pageSize,
        false, adminService.getClubId())" [count]="firmData?.count" [page]="firmData?.page +1"
                        [perPage]="firmData?.pageSize" [pagesToShow]="5">
        </app-pagination>
    </div>
</div>
