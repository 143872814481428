<div class="numberplate-search-wrapper">
    <app-car-by-license-vwe
            *ngIf="isActiveNumberplate('VWE')"
            [numberplate]="numberplate"
            (applyCars)="applyCars.emit($event)">
    </app-car-by-license-vwe>

    <app-car-by-license-fr
            *ngIf="(isActiveNumberplate('AUTODATA') || isActiveNumberplate('CATDATA') || isActiveNumberplate('TECDOCRMIVIN') || isActiveNumberplate('TECDOC')) && userService.accountData.country ==='FR'"
            [numberplate]="numberplate"
            (applyCars)="applyCars.emit($event)">
    </app-car-by-license-fr>

    <app-car-by-license-uk
            *ngIf="(isActiveNumberplate('TECDOC') || isActiveNumberplate('TECDOCRMIVIN')) && userService.accountData.country ==='GB'"
            [numberplate]="numberplate"
            (applyCars)="applyCars.emit($event)">
    </app-car-by-license-uk>

    <app-car-by-license-nl
            *ngIf="(isActiveNumberplate('TECDOC') || isActiveNumberplate('TECDOCRMIVIN')) && userService.accountData.country ==='NL'"
            [numberplate]="numberplate"
            (applyCars)="applyCars.emit($event)">
    </app-car-by-license-nl>

    <app-car-by-license-es
            *ngIf="(isActiveNumberplate('TECDOC') || isActiveNumberplate('TECDOCRMIVIN')) && userService.accountData.country ==='ES'"
            [numberplate]="numberplate"
            (applyCars)="applyCars.emit($event)">
    </app-car-by-license-es>

    <app-car-by-license-pt
            *ngIf="(isActiveNumberplate('TECDOC') || isActiveNumberplate('TECDOCRMIVIN')) && userService.accountData.country ==='PT'"
            [numberplate]="numberplate"
            (applyCars)="applyCars.emit($event)">
    </app-car-by-license-pt>

    <app-car-by-license-ch
            *ngIf="(isActiveNumberplate('TECDOC') || isActiveNumberplate('TECDOCRMIVIN')) && userService.accountData.country ==='CH'"
            [numberplate]="numberplate"
            (applyCars)="applyCars.emit($event)">
    </app-car-by-license-ch>

    <app-car-by-license-it
            *ngIf="(isActiveNumberplate('TECDOC') || isActiveNumberplate('TECDOCRMIVIN')) && userService.accountData.country ==='IT'"
            [numberplate]="numberplate"
            (applyCars)="applyCars.emit($event)">
    </app-car-by-license-it>
</div>
