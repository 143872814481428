<div class="maintenance">
    <app-default-loading-page
            *ngIf="(stateService.maintenanceContent | async) === 'LOADING'"></app-default-loading-page>
    <div class="content sidebar-opener-wrapper" [ngClass]="{'browserIsIE': helperService.browserIsIE()}"
         *ngIf="(stateService.maintenanceContent | async) === 'CONTENT'">
        <app-message [title]="'PROMPTS.MESSAGE.DELETE_MAINTENANCE_TITLE' | translate"
                     [textFirst]="'PROMPTS.MESSAGE.DELETE_MAINTENANCE_TEXT_FIRST' | translate"
                     [textSecond]="'PROMPTS.MESSAGE.DELETE_MAINTENANCE_TEXT_SECOND' | translate"
                     [btn]="['PROMPTS.BUTTONS.CANCEL' | translate, 'PROMPTS.BUTTONS.NO' | translate, 'PROMPTS.BUTTONS.YES' | translate]"
                     (onBtnClick)="maintenanceService.onMessageBtnClick($event, true)"
                     (onModalClose)="maintenanceService.showDependencyError = false"
                     *ngIf="maintenanceService.showDependencyError">
        </app-message>
        <div class="sidebar-opener" [ngClass]="userService?.getNavigation()">
            <ul>
                <li (click)="showNavigation()">
                    {{ 'GENERAL.NAVIGATION.NAVIGATION' | translate }}
                </li>
                <li (click)="showFilter()" [ngClass]="{'disabled':!hasFilter()}">
                    {{ 'GENERAL.NAVIGATION.FILTER' | translate }}
                </li>
                <li (click)="userService?.toggleNavigation()">
                    <i class="fa fa-chevron-right"></i>
                </li>
            </ul>
        </div>
        <!--left panel-->
        <div class="left-panel no-float" [ngClass]="userService?.getNavigation()">
            <div class="panel-content">
                <div class="inner-content">
                    <ngb-tabset #tabSet>

                        <!-- Wartung -->
                        <ngb-tab title="{{'CLUB_SETTINGS.NAVIGATION.MAINTENANCE' | translate}}">
                            <ng-template ngbTabContent>
                                <div class="padding-top">
                                    <ul class="custom-tree" *ngIf="right_sidebar != 'article'"
                                        (click)="maintenanceService.selectedArticle = undefined; maintenanceService.searchResult = undefined; maintenanceService.resetSelectedUrl(); maintenanceService.showDocumentWithLink = undefined">
                                        <li class="list"
                                            *ngIf="(maintenanceService?.maintenance?.mdAddItemMpKorWorksDTO?.length > 0) && (maintenanceService?.maintenance?.mdItemKorWorksDTO?.length > 0) && !cartService.isOrder()"
                                            (click)="maintenanceService.showDocumentWithLink = undefined">
                                            <div class="clickable" routerLink="/maintenance/plan"
                                                 routerLinkActive="active">
                                                <i class="folder fa fa-circle" routerLink="/maintenance/plan"
                                                   routerLinkActive="open"></i>
                                                <span>{{'MAINTENANCE.MAIN_WORK' | translate}}</span>
                                            </div>
                                        </li>
                                        <li class="list"
                                            *ngIf="!(maintenanceService?.maintenance?.mdAddItemMpKorWorksDTO?.length > 0) || !(maintenanceService?.maintenance?.mdItemKorWorksDTO?.length > 0)">
                                            <div class="noData" tooltip tooltipPosition="bottom"
                                                 tooltipContent="{{'CAR_HISTORY.TOOLTIP.NO_DATA' | translate}}">
                                                <i class="folder fa fa-circle"></i>
                                                <span>{{'MAINTENANCE.MAIN_WORK' | translate}}</span>
                                            </div>
                                        </li>
                                        <li class="list"
                                            *ngIf="maintenanceService?.gearBox?.length > 0 && !cartService.isOrder()">
                                            <div class="clickable" routerLink="/maintenance/suggestion"
                                                 routerLinkActive="active">
                                                <i class="folder fa fa-circle" routerLink="/maintenance/suggestion"
                                                   routerLinkActive="open"></i>
                                                <span>{{'MAINTENANCE.SUGGESTION' | translate}}</span>
                                            </div>
                                        </li>
                                        <li class="list" *ngIf="!(maintenanceService?.gearBox?.length > 0)">
                                            <div class="noData" tooltip tooltipPosition="bottom"
                                                 tooltipContent="{{'CAR_HISTORY.TOOLTIP.NO_DATA' | translate}}">
                                                <i class="folder fa fa-circle"></i>
                                                <span>{{'MAINTENANCE.SUGGESTION' | translate}}</span>
                                            </div>
                                        </li>
                                        <li class="list">
                                            <div class="clickable" routerLink="/maintenance/info"
                                                 routerLinkActive="active">
                                                <i class="folder fa fa-circle" routerLink="/maintenance/info"
                                                   routerLinkActive="open"></i>
                                                <span>{{'MAINTENANCE.INFO' | translate}}</span>
                                            </div>
                                            <ul class="custom-tree">
                                                <li class="list" *ngIf="maintenanceService.manualUrl">
                                                    <div class="clickable" routerLink="/maintenance/info/manufacturer"
                                                         routerLinkActive="active">
                                                        <i class="folder fa fa-circle"
                                                           routerLink="/maintenance/info/manufacturer"
                                                           routerLinkActive="open"></i>
                                                        <span>{{'MAINTENANCE.MANUFACTURER_INFO' | translate}}</span>
                                                    </div>
                                                </li>
                                                <li class="list" *ngIf="!maintenanceService.manualUrl">
                                                    <div class="noData" tooltip tooltipPosition="bottom"
                                                         tooltipContent="{{'CAR_HISTORY.TOOLTIP.NO_DATA' | translate}}">
                                                        <i class="folder fa fa-circle"></i>
                                                        <span>{{'MAINTENANCE.MANUFACTURER_INFO' | translate}}</span>
                                                    </div>
                                                </li>
                                                <li class="list" *ngIf="userService.allow('MAINTENANCE-DATA')">
                                                    <div class="clickable" routerLink="/maintenance/info/specifications"
                                                         routerLinkActive="active">
                                                        <i class="folder fa fa-circle"
                                                           routerLink="/maintenance/info/specifications"
                                                           routerLinkActive="open"></i>
                                                        <span>{{'MAINTENANCE.MANUFACTURER_SPEC' | translate}}</span>
                                                    </div>
                                                </li>
                                                <li class="list" *ngIf="userService.allow('MAINTENANCE-DATA')">
                                                    <div class="clickable"
                                                         routerLink="/maintenance/info/change-interval"
                                                         routerLinkActive="active">
                                                        <i class="folder fa fa-circle"
                                                           routerLink="/maintenance/info/change-interval"
                                                           routerLinkActive="open"></i>
                                                        <span>{{'MAINTENANCE.CHANGE_INTERVAL' | translate}}</span>
                                                    </div>
                                                </li>
                                                <li class="list" (click)="maintenanceService.getChangeInterval()">
                                                    <div class="clickable" routerLink="/maintenance/info/service"
                                                         routerLinkActive="active">
                                                        <i class="folder fa fa-circle"
                                                           routerLink="/maintenance/info/service"
                                                           routerLinkActive="open"></i>
                                                        <span>{{'MAINTENANCE.SERVICEPLAN' | translate}}</span>
                                                    </div>
                                                <li class="list"
                                                    (click)="maintenanceService.setSelectedCarInfos(carService.selectedCar.serviceInfos)">
                                                    <div class="clickable" routerLink="/maintenance/info/warnings"
                                                         routerLinkActive="active">
                                                        <i class="folder fa fa-circle"
                                                           routerLink="/maintenance/info/warnings"
                                                           routerLinkActive="open"></i>
                                                        <span>{{'CAR_HISTORY.WARNING.TITLE' | translate}}</span>
                                                    </div>
                                                </li>
                                                <li class="list"
                                                    *ngIf="configService.isActiveTool('DIGITAL_SERVICEBOOK') && carService?.selectedCar?.digitalServiceBook"
                                                    (click)="carService.openServiceBook()">
                                                    <div class="clickable">
                                                        <i class="folder fa fa-circle"></i>
                                                        <span>{{'CART.SERVICEBOOK' | translate}}</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </li>
                                        <li class="list" *ngIf="maintenanceService?.manual?.length > 0">
                                            <div class="clickable" routerLink="/maintenance/hints"
                                                 routerLinkActive="active">
                                                <i class="folder fa fa-circle"
                                                   routerLink="/maintenance/hints"
                                                   routerLinkActive="open"></i>
                                                <span>{{'TECHNICALINFOS.NAV.MAINTENANCE' | translate}}</span>
                                            </div>
                                            <ul class="custom-tree">
                                                <li class="list" *ngFor="let manual of maintenanceService?.manual">
                                                    <div class="clickable" routerLink="/maintenance/hints/{{manual.id}}"
                                                         routerLinkActive="active">
                                                        <i class="folder fa fa-circle"
                                                           routerLink="/maintenance/hints/{{manual.id}}"
                                                           routerLinkActive="open"></i>
                                                        <span>{{manual?.name}}</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </li>
                                        <li class="list" *ngIf="maintenanceService?.manual?.length === 0">
                                            <div class="clickable noData"
                                                 tooltip
                                                 tooltipPosition="bottom"
                                                 tooltipContent="{{'CAR_HISTORY.TOOLTIP.NO_DATA' | translate}}">
                                                <i class="folder fa fa-circle"></i>
                                                <span>{{'TECHNICALINFOS.NAV.MAINTENANCE' | translate}}</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </ng-template>
                        </ngb-tab>

                        <!-- Filter -->
                        <ngb-tab
                                [disabled]="!(maintenanceService?.searchResult) || router.url.indexOf('/plan') < 0 || maintenanceService?.showAdditionalWork">
                            <ng-template ngbTabTitle>
                                <div [@bounceIn]="maintenanceService?.searchResult?.possibleFilters?.length > 0">
                                    <i class="fa fa-filter"
                                       *ngIf="maintenanceService?.searchResult?.possibleFilters?.length > 0 && maintenanceService?.articleList.length > 0"></i>
                                    <span style="margin-left: 5px;">{{'SPAREPARTS_VIEW.FILTER' | translate}}</span>
                                    <span *ngIf="maintenanceService?.searchResult?.possibleFilters?.length > 0 && maintenanceService?.articleList.length > 0">(<span>{{maintenanceService?.searchResult?.possibleFilters?.length}}</span>)</span>
                                </div>
                            </ng-template>
                            <ng-template ngbTabContent>
                                <div class="button-header">
                                    <button class="base-btn"
                                            [disabled]="maintenanceService.articlesLoading || maintenanceService.infinityLoading"
                                            (click)="maintenanceService.updateFilter()">
                                        {{'GENERAL.UPDATE' | translate}}
                                    </button>
                                    <button class="base-btn ml-3"
                                            *ngIf="!configService.getAccountDataSettingActive('settings', 'DEFAULT_SEARCH_FOR_ALL_ARTICLES')"
                                            (click)="maintenanceService.showAllArticles()">
                                        {{'SPAREPARTS_VIEW.SHOW_ALL' | translate}}
                                    </button>
                                    <button class="base-btn ml-3"
                                            *ngIf="maintenanceService?.searchResult?.possibleFilters?.length > 0 || maintenanceService?.searchResult?.searchForAllArticles"
                                            (click)="maintenanceService.resetFilter()">
                                        {{'SPAREPARTS_VIEW.FILTER_RESET' | translate}}
                                    </button>
                                </div>
                                <div class="scroll-content">
                                    <ng-container
                                            *ngFor="let groups of maintenanceService?.searchResult?.possibleFilters">
                                        <app-collapsible-panel
                                                [folded]="maintenanceService?.searchResult?.possibleFilters.length !== 1 && !(articlesService.foldedCollapsePanel.indexOf(groups.groupName) > -1)"
                                                (onChangeFolded)="maintenanceService.toggleFolded($event, groups.groupName)"
                                                [panelTitle]="groups.groupName | translate"
                                                *ngIf="groups.groupId === 'BRAND'">
                                            <div class="filterVar" *ngFor="let filter of groups.filters">
                                                <app-checkbox *ngIf="!filter.fixedFilter"
                                                              [isChecked]="maintenanceService.findFilter(filter, true)"
                                                              [labelText]="filter.displayValue"
                                                              (onChange)="maintenanceService.toggleActiveFilter(filter)"></app-checkbox>
                                                <app-checkbox *ngIf="filter.fixedFilter"
                                                              [isDisabled]="true"
                                                              [isChecked]="maintenanceService.findFilter(filter, true)"
                                                              [labelText]="filter.displayValue"></app-checkbox>
                                            </div>
                                        </app-collapsible-panel>
                                    </ng-container>

                                    <ng-container
                                            *ngFor="let groups of maintenanceService?.searchResult?.possibleFilters">
                                        <app-collapsible-panel
                                                [folded]="!(maintenanceService.foldedCollapsePanel.indexOf(groups.groupName) > -1)"
                                                (onChangeFolded)="maintenanceService.toggleFolded($event, groups.groupName)"
                                                [panelTitle]="groups.groupName"
                                                *ngIf="groups.groupId !== 'BRAND'">
                                            <div class="filterVar" *ngFor="let filter of groups.filters">
                                                <app-checkbox *ngIf="!filter.fixedFilter"
                                                              [isChecked]="maintenanceService.findFilter(filter, true)"
                                                              [labelText]="filter.displayValue"
                                                              (onChange)="maintenanceService.toggleActiveFilter(filter)">
                                                </app-checkbox>
                                                <app-checkbox *ngIf="filter.fixedFilter"
                                                              [isChecked]="maintenanceService.findFilter(filter, true)"
                                                              [labelText]="filter.displayValue"
                                                              [isDisabled]="true">
                                                </app-checkbox>
                                            </div>
                                        </app-collapsible-panel>
                                    </ng-container>

                                    <div class="alert alert-info text-center"
                                         *ngIf="!(maintenanceService?.searchResult?.possibleFilters?.length > 0)">
                                        <i class="fa fa-info-circle mr-2"></i>
                                        <span>{{'PROMPTS.MESSAGE.NO_FILTER' | translate}}</span>
                                    </div>
                                </div>
                            </ng-template>
                        </ngb-tab>
                    </ngb-tabset>
                </div>
            </div>
        </div>
        <!--center panel-->
        <div class="center-panel no-float" [ngClass]="userService?.getNavigation()">
            <app-default-loading-page *ngIf="maintenanceService?.loadMaintenance"></app-default-loading-page>
            <router-outlet #route="outlet"></router-outlet>
        </div>
        <!--right panel-->
        <div class="right-panel no-float" [ngClass]="userService?.getNavigation()"
             [@sidebar]="( maintenanceService.expanded_right ? 'in': 'out' )">
            <div class="panel-content right-sidebar">
                <div class="inner-content">
                    <app-default-loading-page
                            *ngIf="maintenanceService?.additionalDetailsLoading"></app-default-loading-page>
                    <app-work-details
                            *ngIf="(maintenanceService.additionalDetailsLoading || maintenanceService.selectedItem) && router.url.indexOf('/plan') > -1  && !maintenanceService.selectedArticle"
                            [service]="maintenanceService"></app-work-details>
                    <app-article-details [showBelongingWork]="false" [showArtikelLists]="false"
                                         [service]="maintenanceService"></app-article-details>
                    <app-car-details
                            *ngIf="!maintenanceService.selectedArticle && (!maintenanceService.selectedItem || router.url.indexOf('/plan') === -1 && maintenanceService.selectedItem) && !maintenanceService.additionalDetailsLoading"></app-car-details>
                </div>
            </div>
        </div>
    </div>

    <app-content-overlay [showContent]="stateService.maintenanceContent | async"
                         [newRoute]="'/maintenance'"></app-content-overlay>

    <ng-container *ngIf="maintenanceService.showGallery">
        <app-gallery [title]="maintenanceService.selectedArticle.description"
                     [selectedImage]="maintenanceService.selectedImage"
                     [documentList]="maintenanceService.filterImages()"
                     (closeGallery)="maintenanceService.showGallery = false"></app-gallery>
    </ng-container>

</div>
