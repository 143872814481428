<div class="heading blue">
        <span class="clickable" routerLink="/company-admin">
            <i class="fa fa-th" aria-hidden="true"></i>
            {{'ADMIN_PANEL.OVERVIEW' | translate}}
        </span>
    <span>
    <i class="fa fa-caret-right"></i>
    {{'USER_PROFILE.SETTINGS' | translate}}</span>
</div>

<div class="panel-content center-content white-background">
  <div class="assembly-categories">
    <div class="assembly-category" (click)="navigate('/company-admin/settings/wage-rate')">
      <div class="name-wrapper-no-image">
        {{'USER_PANEL.HOURLY_RATE' | translate}}
      </div>
    </div>
      <div class="assembly-category" (click)="navigate('/company-admin/settings/logo')">
          <div class="name-wrapper-no-image">
              {{'USER_PROFILE.FIRM_LOGO' | translate}}
          </div>
      </div>
      <div class="assembly-category" (click)="navigate('/company-admin/settings/advanced')">
          <div class="name-wrapper-no-image">
              {{'USER_PROFILE.EXTENDED_OPTIONS' | translate}}
          </div>
      </div>
  </div>
</div>
