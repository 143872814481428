<div class="autovista-details" *ngIf="selectedArticle">
  <ngb-tabset #autovistaTabSet>

    <!-- Artikel -->
    <ngb-tab [title]="'GENERAL.ARTICLE' | translate">
      <ng-template ngbTabContent>

        <!-- side selection -->
        <div class="row side-selection" *ngIf="selectedArticle?.content?.length > 1">
          <div class="col side-text">
            <span>{{'AUTOVISTA.INSTALL_SIDE' | translate}}</span>
          </div>
          <div class="col">
            <div class="side">
              <ng-container *ngFor="let side of selectedArticle?.content">
                <div class="sideButton" [ngClass]="{'active': selectedSide === side}" (click)="selectedSide = side; setSelectedGroups()">
                  <span>{{getSide(side.side)}}</span>
                </div>
              </ng-container>
            </div>
          </div>
        </div>

        <!-- Article list -->
        <div class="article-list" *ngIf="autovistaService?.selectedGroups?.length > 0">
          <div class="row" *ngFor="let group of autovistaService.selectedGroups" (click)="setDetail(group)">
            <div class="col article">
              <i class="fa fa-circle" [ngClass]="{'selected': group === selectedGroup}"></i>
              <span class="article-name">{{group.name}} <span *ngIf="group.criteriaName">({{group.criteriaName}})</span></span>
            </div>
          </div>
        </div>

        <!-- DATA ERROR -->
        <ng-container *ngIf="!(autovistaService?.selectedGroups?.length > 0)">
          <div class="alert alert-info article-alert text-center">
            <i class="fa fa-info-circle mr-2"></i>
            <span>{{'AUTOVISTA.NO_DATA.TITLE' | translate}}</span>
            <br><br>
            <span>{{'AUTOVISTA.NO_DATA.TEXT' | translate}}</span>
          </div>
        </ng-container>
      </ng-template>
    </ngb-tab>


    <!-- Details -->
    <ngb-tab [title]="'SPAREPARTS_VIEW.DETAILS' | translate">
      <ng-template ngbTabContent>
        <div class="details-tab" *ngIf="selectedGroup">
          <div class="row">
            <div class="col">
              <div class="row">
                <div class="col">
                  <span class="articleName">{{selectedGroup.name}}</span><br />
                  <span class="critName" *ngIf="selectedGroup.criteriaName">{{selectedGroup.criteriaName}}</span>
                  <span class="optionName" *ngFor="let name of selectedGroup.optionNames">{{name}}<br /></span>
                  <div class="spacer"></div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <span class="articlePrice price_label" *ngIf="!userService.sale && cartService?.cartCount?.calculationData">{{'CART.SELLOUT' | translate}}</span>
                    <span class="articlePrice"
                          *ngIf="!userService.sale && selectedGroup.parts[0]">{{selectedGroup.parts[0].netPriceFormatted}}</span>
                    <span class="articlePrice"
                          *ngIf="userService.sale && selectedGroup.parts[0]">{{selectedGroup.parts[0].grossPriceFormatted}}</span>
                  <div class="spacer"></div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <span class="infoType">{{'HOME.MANUFACTURER' | translate}}</span>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <span *ngIf="selectedGroup.parts[0]">{{selectedGroup.parts[0].manufacturer}}</span>
                  <div class="spacer"></div>
                </div>
              </div>
            </div>
            <div class="col">
              <img class="detailImage" src="/assets/images/no-image.png">
            </div>
          </div>

          <!-- Varianten -->
          <app-collapsible-panel *ngIf="selectedGroup.parts.length > 0" [panelTitle]="'AUTOVISTA.VARIANTS' | translate" [folded]="false">
            <ng-container *ngFor="let part of selectedGroup.parts; index as i">
              <div class="row options">
                <div class="col-2" *ngIf="autovistaService?.oeSuppliers?.length > 0">
                  <app-checkbox [type]="'radio'" [isDisabled]="loadAvailability" [isChecked]="part === selectedPart" (onChange)="selectPart(part)"></app-checkbox>
                </div>
                <div class="" *ngIf="i > 0" [ngClass]="{'col-3': autovistaService?.oeSuppliers?.length > 0, 'col-5': !(autovistaService?.oeSuppliers?.length > 0)}">
                  <span *ngFor="let name of part.optionNames">{{name}} <br /></span>
                </div>
                <div class="" *ngIf="i === 0" [ngClass]="{'col-3': autovistaService?.oeSuppliers?.length > 0, 'col-5': !(autovistaService?.oeSuppliers?.length > 0)}">
                  <span>{{'FIRM_SETTINGS.OPTIONS.STANDARD' | translate}}</span>
                </div>
                <div class="col-3">
                  <span *ngIf="!userService.sale">{{part.netPriceFormatted}}</span>
                  <span *ngIf="userService.sale">{{part.grossPriceFormatted}}</span>
                </div>
                <div class="col-4 oeNumber" (click)="oeSearch(part.oeNumber, [])">
                  <span>{{part.oeNumber}}</span>
                  <i class="fa fa-external-link"></i>
                </div>
              </div>
            </ng-container>
          </app-collapsible-panel>

          <!-- Verfügbarkeiten -->
          <app-collapsible-panel [panelTitle]="'SPAREPARTS_VIEW.AVAILABILITY' | translate " *ngIf="autovistaService?.oeSuppliers?.length > 0" class="striped-panel" noPadding="true">
            <div class="striped-panel availability-panel">
              <div class="row quantity-change">
                <div class="col-6 col-xl-4">
                  <span class="font-weight-bold">{{'CART.AMOUNT' | translate}}</span>
                </div>
                <div class="col-6 col-xl-3">
                  <app-debounce-input [value]="requestedQuantity" [type]="'number'" [delay]="400" (onChange)="setRequestedQuantity($event); startAvailabailityFlux()"></app-debounce-input>
                </div>
              </div>
              <ng-container>
                <div class="row rowTable" *ngFor="let item of autovistaService.oeAvailability; index as i"
                     [ngClass]="{'selected': (item?.supplier && item?.supplier === _toCartPosition?.supplier), 'unselected': _toCartPosition?.supplier && _toCartPosition?.supplier != item?.supplier}"
                     [@selected]="(item?.supplier === _toCartPosition?.supplier) ? 'move': ''"
                     [@visible]="item?.supplier"
                     (click)="changeItem(item, _toCartPosition?.supplier); ">

                  <ng-container>
                    <!--supplier-->
                    <div class=" a-logo">
                      <img *ngIf="item?.supplier" class="img-fluid" [supplier]="item?.supplier" [logo-color]="(item?.schedule != undefined)" title="{{findSupplierName(item?.supplier)}}"/>

                    </div>
                    <!--price-->
                    <div class=" a-price text-right">
                      <i *ngIf="!item?.schedule" class="fa fa-spinner fa-fw" [ngClass]="{'fa-pulse': !(helperService.browserIsIE() || helperService.browserIsEdge())}"></i>
                      <span class="price" *ngIf="!item?.loading && !userService.sale && item?.sellInNetPerPieceFormatted">
                        {{item?.sellInNetPerPieceFormatted}}
                      </span>
                      <span class="price" *ngIf="userService.sale && !item?.loading && item?.sellOutNetPerPieceFormatted">
                        {{item?.sellOutNetPerPieceFormatted}}
                      </span>
                    </div>
                    <!--availability-->
                    <div class=" a-availability" >
                      <i *ngIf="item?.loading" class="fa fa-spinner fa-fw" [ngClass]="{'fa-pulse': !(helperService.browserIsIE() || helperService.browserIsEdge())}"></i>
                      <div *ngFor="let avail of item?.schedule" [ngClass]="avail.availability" style="min-width: 50px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
                        <span class="icon"></span>
                        <span *ngIf="!item?.loading && avail.availability != 'SPECIAL_ORDER'">
                          <span *ngIf="avail.quantity > 0 && avail.quantity != requestedQuantity">{{avail.quantity}}</span>
                          <span *ngIf="avail.depotName && item?.dealerArticleNumber?.length > 0"> {{avail.depotName}}</span>
                          <span *ngIf="avail.deliveryDate"> {{avail.deliveryDate}}</span>
                          <span *ngIf="avail.deliveryTime"> {{avail.deliveryTime}}</span>
                        </span>
                        <span *ngIf="avail.availability == 'SPECIAL_ORDER'"> {{ 'SUPPLIERS.SPECIAL_ORDER_INFO' | translate}}</span>
                        <span *ngIf="!avail.depotName && avail.availability != 'SPECIAL_ORDER'"
                              [ngClass]="{'light' :  (avail.availability != 'AVAILABLE' && avail.availability != 'ASAP' && avail.availability != 'SPECIAL_ORDER' && avail.availability != 'OWN_STOCK' && avail.availability != 'SHIPPING')} ">
                              {{ 'SUPPLIERS.'+avail.availability | translate}}
                        </span>
                        <span *ngIf="item?.supplier && item?.supplier === _toCartPosition?.supplier && avail.tour" >
                          ({{avail.tour}})
                        </span>
                      </div>
                    </div>
                    <div class="col-12 a-order button-div pl-0">
                      <div class="row cart">
                        <div class="col-xl-6 text-left pl-0">
                          <div [ngClass]="{'a-supplier-vpe-area': (!userService.sale && item?.sellInNetPerPieceFormatted) || (userService.sale && item?.sellOutNetPerPieceFormatted )}">
                            <span class="mr-2 pl-3">{{'CART.SUPPLIER_PACKING_UNIT_SHORT' | translate}}</span>
                            <span class="highlight-color">{{item?.supplierVpe ? item.supplierVpe : '-'}}</span>
                          </div>
                          <span *ngIf="!userService.sale && item?.sellInNetPerPieceFormatted">
                            <span class="mr-2 pl-3">{{'CART.SINGLE_PRICE' | translate}}</span>
                            <span class="highlight-color">{{item?.sellInNetPerPieceFormatted}}</span>
                          </span>
                          <span *ngIf="userService.sale && item?.sellOutNetPerPieceFormatted">
                            <span class="mr-2 pl-3">{{'CART.SINGLE_PRICE' | translate}}</span>
                            <span class="highlight-color">{{item?.sellOutNetPerPieceFormatted}}</span>
                          </span>
                          <div class="a-total-price pl-3" *ngIf="item?.requestedQuantity > 1 && (getTotalPrice() || item?.sellOutNetTotalFormatted)">
                           <span *ngIf="!userService.sale">
                             <span class="price">{{'CART.TOTAL_PRICE' | translate}}
                               <span class="highlight-color">{{getTotalPrice()}}</span>
                             </span></span>
                          <span *ngIf="userService.sale && item?.sellOutNetTotalFormatted">
                            <span class="price">{{'CART.TOTAL_PRICE' | translate}}
                              <span class="highlight-color">{{item?.sellOutNetTotalFormatted}}</span>
                            </span>
                           </span>
                        </div>
                        </div>
                        <div class="col-xl-6 text-right">
                          <button *ngIf="cartService?.isNewestCart() && !cartService.isOrder()" [@submit]="toCartLoading" [disabled]="toCartLoading === 1" (click)="addToCart(_toCartPosition, $event)" class="base-btn in-cart-btn" [ngClass]="{'error': toCartLoading === 2}">
                            <span *ngIf="toCartLoading === 0"><i class="fa fa-shopping-cart"></i> +{{requestedQuantity}} {{'CART.PIECE' | translate}}</span>
                            <span *ngIf="toCartLoading === 1"><i class="fa fa-spinner font-color" [ngClass]="{'fa-pulse': !(helperService.browserIsIE() || helperService.browserIsEdge())}"></i> </span>
                            <span *ngIf="toCartLoading === 2 "><i class="fa fa-exclamation-triangle"></i> +{{requestedQuantity}} {{'CART.PIECE' | translate}}</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
                <div class="row no-supplier-row">
                  <div class="col-8">
                    <span>{{'ARTICLE_SELECTION.NO_SUPPLIER' | translate}}</span>
                  </div>
                  <div class="col-4">
                    <button class="base-btn" (click)="addArticleWithoutSupplier()">
                      <i class="fa fa-shopping-cart" *ngIf="!loadAddToCart"></i>
                      <i class="fa fa-spinner" [ngClass]="{'fa-pulse': !helperService.browserIsEdge() && !helperService.browserIsIE()}" *ngIf="loadAddToCart"></i>
                      <span>+{{requestedQuantity}} {{'CART.PIECE' | translate}}</span>
                    </button>
                  </div>
                </div>
              </ng-container>
            </div>
          </app-collapsible-panel>

          <!-- Arbeitspositionen -->
          <ng-container *ngFor="let workPosition of selectedGroup.workPositions">
            <app-collapsible-panel
                    [panelTitle]="('AUTOVISTA.WORKPOSITIONS' | translate) + ' ' + (workPosition.displayName !== 'Standard' ? workPosition.displayName : '')"
                    [folded]="false"
                    [noPadding]="true"
                    *ngIf="selectedGroup?.workPositions?.length > 0 && selectedGroup?.workPositions[0]?.groups?.length > 0"
                    class="striped-panel">
              <table class="striped-table">
                <tr *ngFor="let work of workPosition.groups">
                  <td>{{ work.mountPositionText + ' ' + work.repairText }}
                    <div *ngIf="work.itemQualifierText != 'Standard'"><b>{{
                      work.itemQualifierText}}</b></div>
                  </td>
                  <td>
                    <span>{{ work.quantity }} {{ 'GENERAL.VALUES.' + work.quantityUnit | translate }}</span>&nbsp;
                    <span *ngIf="work.workPositions?.length > 0">
                      <ng-template #popContent>
                        <div class="work-position" *ngFor="let item of work.workPositions">
                          <span class="work-time">{{ item.quantity }} {{ 'GENERAL.VALUES.' + item.quantityUnit | translate }}</span> -
                          <span class="work-text">{{ item.mountPositionText + ' ' + item.repairText }}</span>
                        </div>
                      </ng-template>

                      <i triggers="mouseenter:mouseleave"
                         [ngbPopover]="popContent"
                         container="body"
                         class="clickable">
                        <i class="fa fa-info"></i>
                      </i>
                    </span>
                  </td>
                  <td>
                    <div class="custom-checkbox white-background mt-1">
                      <app-checkbox [isChecked]="work.posIdInCart > 0"
                                    [isDisabled]="cartService.isOrder()"
                                    (onChange)="toggleWork(work)"></app-checkbox>
                    </div>
                  </td>
                </tr>
              </table>
            </app-collapsible-panel>
          </ng-container>
        </div>
        <div class="details-tab" *ngIf="!selectedGroup">
          <div class="alert alert-info text-center">
            <i class="fa fa-info-circle mr-2"></i>
            <span>{{'AUTOVISTA.NO_ARTICLE_INFO' | translate}}</span>
          </div>
        </div>
      </ng-template>
    </ngb-tab>
  </ngb-tabset>
</div>
