<div class="user-email">
    <div class="heading blue">
         <span class="clickable"  routerLink="/company-admin">
             <i class="fa fa-th" aria-hidden="true"></i>
             {{'ADMIN_PANEL.OVERVIEW' | translate}}
         </span>
        <span class="clickable" routerLink="/company-admin/profile">
            <i class="fa fa-caret-right"></i>
            {{'USER_PROFILE.MY_EMAIL' | translate}}
        </span>
    </div>
    <div class="panel-content center-content white-background">
        <div class="row">
            <div class="col-12">
                <h1>{{'USER_PROFILE.CHANGE_EMAIL' | translate}}</h1>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <app-material-form-input [text]="userService?.accountData?.email"
                                         [type]="'email'"
                                         [disabled]="(userService.authMethode$ | async) === 'N4SSO'"
                                         [showValidation]="true"
                                         [validationErrorMessage]="'VALIDATION.ERROR_EMAIL' | translate"
                                         [label]="'USER_PANEL.MAIL' | translate"
                                         [pattern]="'^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$'"
                                         (isValid)="emailIsValid = $event;"
                                         (textChange)="email = $event">
                </app-material-form-input>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <button class="base-btn success" (click)="changeEmail()" [disabled]="!emailIsValid">
                    <i class="fa fa-check"></i>
                    <span>{{'USER_PROFILE.CHANGE_EMAIL' | translate}}</span>
                </button>
            </div>
        </div>
    </div>
</div>
