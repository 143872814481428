<div class="advanced">
    <div class="heading blue">
    <span class="clickable" *ngIf="!location?.path()?.includes('/admin/firm/advanced/')" routerLink="/company-admin">
        <i class="fa fa-th" aria-hidden="true"></i>
        {{'ADMIN_PANEL.OVERVIEW' | translate}}
    </span>
    <span class="clickable" *ngIf="location?.path()?.includes('/admin/firm/advanced/')" routerLink="/admin">
        <i class="fa fa-th" aria-hidden="true"></i>
            {{'ADMIN_PANEL.OVERVIEW' | translate}}
    </span>
    <span *ngIf="!location?.path()?.includes('/admin/firm/advanced/')" class="clickable" routerLink="/company-admin/settings">
        <i class="fa fa-caret-right"></i>
        {{'USER_PROFILE.SETTINGS' | translate}}
    </span>
        <span>
        <i class="fa fa-caret-right"></i>
        {{'USER_PROFILE.EXTENDED_OPTIONS' | translate}}
    </span>
    </div>

    <div class="panel-content center-content white-background">
        <div class="firmdataCSS">
            <div class="row" *ngIf="firmSettings">
                <div class="col-12">
                    <div class="row">
                        <div class="col headerType">
                            <h1>{{'USER_PROFILE.EXTENDED_OPTIONS' | translate}}</h1>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <span class="subHeader">{{ 'FIRM_SETTINGS.LABOUR_UNIT_TYPE' | translate}}</span>
                            <hr/>
                        </div>
                    </div>
                    <div class="row spacer">
                        <div class="col">
                            <ng-container *ngFor="let value of getLabourUnitType()?.possibleValues">
                                <app-checkbox [isChecked]="isCheckedLabourUnitType(value)"
                                              (onChange)="setLabourUnitType(value)" [type]="'radio'"
                                              [labelText]="'FIRM_SETTINGS.OPTIONS.' + value | translate">
                                </app-checkbox>
                            </ng-container>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <span class="subHeader">{{ 'FIRM_SETTINGS.MAIL' | translate}}</span>
                            <hr/>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <app-checkbox [labelText]="'FIRM_SETTINGS.CONFIRMATION_MAIL' | translate" [isChecked]="checkState('ORDER_CONFIRMATION_MAIL')" (onChange)="changeValue($event, 'ORDER_CONFIRMATION_MAIL')"></app-checkbox>
                        </div>
                    </div>
                    <div class="row spacer">
                        <div class="col">
                            <app-checkbox [labelText]="'FIRM_SETTINGS.PACKING_LIST_BY_MAIL' | translate" [isChecked]="checkState('PACKING_LIST_BY_MAIL')" (onChange)="changeValue($event, 'PACKING_LIST_BY_MAIL')"></app-checkbox>
                        </div>
                    </div>


                    <div class="row">
                        <div class="col">
                            <span class="subHeader">{{ 'FIRM_SETTINGS.ARTIKEL' | translate }}</span>
                            <hr/>
                        </div>
                    </div>
                    <div class="row spacer">
                        <div class="col">
                            <app-checkbox [labelText]="'FIRM_SETTINGS.SHOW_ALL_ARTICLES' | translate" [isChecked]="checkState('DEFAULT_SEARCH_FOR_ALL_ARTICLES')" (onChange)="changeValue($event, 'DEFAULT_SEARCH_FOR_ALL_ARTICLES')"></app-checkbox>
                        </div>
                    </div>


                    <div class="row">
                        <div class="col">
                            <span class="subHeader">{{'FIRM_SETTINGS.OPTIONS.TITLE' | translate}}</span>
                            <hr/>
                        </div>
                    </div>
                    <div class="row spacer">
                        <div class="col">
                            <ng-container *ngFor="let value of getCartCreationBehavior()?.possibleValues">
                                <app-checkbox [isChecked]="getPreCartCreationBehavior(value)" (onChange)="setCartCreationBehavior(value)" [type]="'radio'" [labelText]="'FIRM_SETTINGS.OPTIONS.' + value | translate"></app-checkbox>
                                <div class="option_info">
                                    <span>{{'FIRM_SETTINGS.OPTIONS.INFO.' + value | translate}}</span>
                                </div>
                            </ng-container>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <span class="subHeader">{{'FIRM_SETTINGS.AUTOVISTA_VIN' | translate}}</span>
                            <hr/>
                        </div>
                    </div>
                    <div class="row spacer">
                        <div class="col">
                            <app-checkbox [isChecked]="getAutovistaAllowChargeable()?.value"
                                          (onChange)="setAutovistaAllowChargeable($event)"
                                          [labelText]="'USER_PROFILE.USER_MANAGEMENT.AUTOVISTA_VIN_SEARCH_ALLOW_CHARGEABLE' | translate">
                            </app-checkbox>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <span class="subHeader">{{ 'FIRM_SETTINGS.USE_COMPOSITE_WORKTIMES_TITLE' | translate }}</span>
                            <hr/>
                        </div>
                    </div>
                    <div class="row spacer">
                        <div class="col">
                            <app-checkbox [labelText]="'FIRM_SETTINGS.USE_COMPOSITE_WORKTIMES' | translate" [isChecked]="checkState('USE_COMPOSITE_WORKTIMES')" (onChange)="changeValue($event, 'USE_COMPOSITE_WORKTIMES')"></app-checkbox>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <span class="subHeader">{{'FIRM_SETTINGS.CATALOG_MODE_ORDERING' | translate}}</span>
                            <hr/>
                        </div>
                    </div>
                    <div class="row spacer">
                        <div class="col">
                            <ng-container *ngFor="let value of getPreOrderingBehavior()?.possibleValues">
                                <app-checkbox [isChecked]="getPreOrderingValues(value)" (onChange)="setPreorderingValues(value)" [type]="'radio'" [labelText]="'FIRM_SETTINGS.PREORDERING_TYPE.' + value | translate"></app-checkbox>
                            </ng-container>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <span class="subHeader">{{ 'FIRM_SETTINGS.SERVICEPLAN.TITLE' | translate }}</span>
                            <hr/>
                        </div>
                    </div>
                    <div class="row spacer">
                        <div class="col">
                            <app-checkbox [labelText]="'FIRM_SETTINGS.SERVICEPLAN.SHOW_HEADER' | translate" [isChecked]="checkState('SERVICEPLAN_SHOW_HEADER')" (onChange)="changeValue($event, 'SERVICEPLAN_SHOW_HEADER')"></app-checkbox>
                            <app-checkbox [labelText]="'FIRM_SETTINGS.SERVICEPLAN.SHOW_CAR' | translate" [isChecked]="checkState('SERVICEPLAN_SHOW_CAR')" (onChange)="changeValue($event, 'SERVICEPLAN_SHOW_CAR')"></app-checkbox>
                        </div>
                    </div>

                    <div class="row" *ngIf="userService.allow('SELLIN')">
                        <div class="col">
                            <span class="subHeader">{{ 'FIRM_SETTINGS.SALE_MODE.TITLE' | translate }}</span>
                            <hr/>
                        </div>
                    </div>
                    <div class="row spacer" *ngIf="userService.allow('SELLIN')">
                        <div class="col">
                            <app-checkbox [labelText]="'FIRM_SETTINGS.SALE_MODE.START_IN_SALE_MODE' | translate" [isChecked]="checkState('START_IN_SALE_MODE')" (onChange)="changeValue($event, 'START_IN_SALE_MODE')"></app-checkbox>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <span class="subHeader">{{ 'FIRM_SETTINGS.EXPORT_TYPE' | translate }}</span>
                            <hr/>
                        </div>
                    </div>
                    <div class="row spacer">
                        <div class="col">
                            <ng-container *ngFor="let value of getExportType()?.possibleValues">
                                <app-checkbox [isChecked]="getPreExportType(value)" (onChange)="setExportType(value)" [type]="'radio'" [labelText]="'FIRM_SETTINGS.OPTIONS.' + value | translate"></app-checkbox>
                            </ng-container>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col" *ngIf="companyAdminService.hasChanges">
                            <hr/>
                            <button class="base-btn success" (click)="saveChanges()" *ngIf="companyAdminService.hasChanges">
                                <i class="fa fa-check"></i>
                                {{ 'PROMPTS.BUTTONS.SAVE_CHANGES' | translate }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <app-error-handler *ngIf="errorService.newError"></app-error-handler>
    </div>
</div>
