import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ErrorHandlerService} from '../../../shared/components/error-handler/services/error-handler.service';
import {IFirm} from '../../../shared/interfaces/firm';
import {UserService} from '../../../shared/services/user/user.service';
import {AdminService} from '../../club/services/admin.service';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {UntypedFormGroup} from '@angular/forms';
import {HelperService} from '../../../shared/services/helper.service';

@Component({
    selector: 'app-user-data',
    templateUrl: './user-data.component.html'
})
export class UserDataComponent implements OnInit {

    @Input() userData: any;
    @Input() firmData: IFirm;
    @Output() public _back: EventEmitter<any> = new EventEmitter();
    @Output() public _update: EventEmitter<any> = new EventEmitter();
    @Output() public _updateReset: EventEmitter<any> = new EventEmitter();
    public states: any;
    public licenseRoles: string[] = ["ROLE_USER_LIGHT", "ROLE_USER_PLUS", "ROLE_USER_PLUS_AW",  "ROLE_USER_PLUS_AW_TEC"]

    userForm = new UntypedFormGroup({
        // username: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z+\/=0-9@\-_!?]*')]),
        // email: new FormControl('', [Validators.required, Validators.email]),
        // password: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z+\/=0-9@\-_!?]*')])
    });

    constructor(public errorHandlerService: ErrorHandlerService,
                public adminService: AdminService,
                private router: Router,
                private helperService: HelperService,
                public userService: UserService,
                private location: Location) {

        if (this.adminService && this.adminService.club && this.adminService.club.clubId && this.location.path().includes('/admin/')) {
            this.userService.getLocales(this.adminService.club.clubId).subscribe(
                (response) => {
                    this.states = response;
                    if (this.userData && this.userData.language) {
                        if (!(this.states.indexOf(this.userData.language) > -1)) {
                            this.userData.language = this.states[0].language;
                        }
                    } else {
                        this.userData.language = this.states[0].language;
                    }
                }
            );
        } else {
            this.userService.getLocales().subscribe(
                (response) => {
                    this.states = response;
                    if (this.userData && this.userData.language) {
                        if (!this.states.indexOf((this.userData.language) > -1)) {
                            this.userData.language = this.states[0].language;
                        }
                    } else {
                        this.userData.language = this.states[0].language;
                    }
                }
            );
        }
    }

    ngOnInit() {
        if (this.userData && (!this.userData.language || this.userData.language.length === 0)) {
            if (this.userService && this.userService.accountData) {
                this.userData.language = this.userService.accountData.language;
            }
        }
    }

    public reset() {
        this._back.emit();
    }

    public update() {
        this._update.emit();
    }

    public updateReset() {
        this._updateReset.emit();
    }

    public deleteLogin(userData: any, _delete: boolean, right: string) {
        switch (right) {
            case ('GLOBAL-ADMIN'):
                if (!_delete) {
                    userData.delete = true;
                } else {
                    this.adminService.deleteUser(userData.id).subscribe(
                        (success) => {
                            this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_DELETE_ENTRY', 'success');
                            this.adminService.step[0] = [];
                            this.adminService.step[1] = '';
                            this.adminService.step[2] = false;
                            this._back.emit();
                        },
                        (error) => {
                            this.helperService.showNotification('TOAST_MESSAGES.ERROR_DELETE_ENTRY', 'error');
                        }
                    )
                }
                break;
            case ('CLUB-ADMIN'):
                if (!_delete) {
                    userData.delete = true;
                } else {
                    this.adminService.archiveUserClubAdmin(userData.id).subscribe(
                        (success) => {
                            this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_DELETE_ENTRY', 'success');
                            this.adminService.step[0] = [];
                            this.adminService.step[1] = '';
                            this.adminService.step[2] = false;
                            this._back.emit();
                        },
                        (error) => {
                            this.helperService.showNotification('TOAST_MESSAGES.ERROR_DELETE_ENTRY', 'error');
                        }
                    )
                }
                break;
            case ('FIRM-ADMIN'):
                if (!_delete) {
                    userData.delete = true;
                } else {
                    this.adminService.archiveUserFirmAdmin(userData.id).subscribe(
                        (success) => {
                            this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_DELETE_ENTRY', 'success');
                            this.adminService.step[0] = [];
                            this.adminService.step[1] = '';
                            this.adminService.step[2] = false;
                            this._back.emit();
                        },
                        (error) => {
                            this.helperService.showNotification('TOAST_MESSAGES.ERROR_DELETE_ENTRY', 'error');
                        }
                    )
                }
                break;
        }
    }


    public toggleLanguage(event) {
        const language = event.target.value;
        this.userData.language = language;
    }

    public isGlobalAdmin() {
        return this.userService.allow('GLOBAL-ADMIN');
    }

    public isFirmAdmin() {
        return this.userService.allow('FIRM-ADMIN');
    }

    public isClubAdmin() {
        return this.userService.allow('CLUB-ADMIN');
    }

    public toggleSelection(role) {
        const idx = this.userData.roles.indexOf(role);
        if (idx > -1) {
            this.userData.roles.splice(idx, 1);
        } else {
            this.userData.roles.push(role);
        }
    }
}
