import {Component, OnDestroy, OnInit} from '@angular/core';
import {IAdminFirm} from '../../../shared/interfaces/adminFirm';
import {AdminService} from '../services/admin.service';
import {Router} from '@angular/router';
import {ErrorHandlerService} from '../../../shared/components/error-handler/services/error-handler.service';
import {UserService} from '../../../shared/services/user/user.service';
import {HelperService} from '../../../shared/services/helper.service';
import {IFirmListResponse} from '../../../shared/interfaces/DTO/firm/firmListResponse';
import {Subscription} from 'rxjs';
import {ConfigService} from '../../../shared/services/config.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html'
})
export class AdminDashboardComponent implements OnInit, OnDestroy {
    private clubIdSubscription: Subscription;
    public firmData: IFirmListResponse;
    public searchTerm: string;
    public search: boolean;
    public loadFirm: boolean = false;
    public pageSize: number = 10;
    public infinityLoading: boolean = false;
    private lastSearchTimeStamp: number;
    public currentClubId: number;

    constructor(public adminService: AdminService,
                private router: Router,
                public userService: UserService,
                public errorHandlerService: ErrorHandlerService,
                public helperService: HelperService,
                public configService: ConfigService) {
        this.errorHandlerService.newError = false;
    }

    ngOnInit() {
        this.currentClubId = this.adminService.getClubId();
        this.searchFirm('', 0, this.pageSize, false, this.currentClubId);
        this.clubIdSubscription = this.adminService.onChangeClub.subscribe(
            (clubId) => {
                if (this.currentClubId !== clubId) {
                    if (clubId !== null) {
                        this.currentClubId = clubId;
                    }
                    this.resetSearch();
                    this.searchFirm('', 0, this.pageSize, false, this.currentClubId);
                }
            }
        )
        this.errorHandlerService.newError = false;
        this.getStep();
    }

    ngOnDestroy() {
        this.firmData = undefined;
        this.clubIdSubscription.unsubscribe();
    }

    public getStep() {
        if (!this.loadFirm) {
            this.firmData = this.adminService.step[0];
            this.searchTerm = this.adminService.step[1];
            this.search = this.adminService.step[2];
        }
    }

    public setStep() {
        if (!this.loadFirm) {
            this.adminService.step[0] = this.firmData;
            this.adminService.step[1] = this.searchTerm;
            this.adminService.step[2] = this.search;
        }
    }

    public resetSearch() {
        this.search = false;
        this.searchTerm = '';
        this.firmData = undefined;
        this.adminService.selectedFirm = undefined;
        this.setStep();
    }

    public newFirm() {
        this.router.navigate(['admin/', 'create']);
    }

    public selectedFirm(firm: any) {
        this.adminService.selectedFirm = firm;
        this.navigate(firm);
        this.setStep();
    }

    public deletFirm(firm: any, _delete: boolean) {
        if (!_delete) {
            firm.delete = true;
        } else {
            this.adminService.deleteFirm(firm.firmId).subscribe(
                (response) => {
                    this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_DELETE_ENTRY', 'success');
                    this.searchFirm(this.searchTerm, 0, 1, false, this.currentClubId);
                },
                (error) => {
                    this.helperService.showNotification('TOAST_MESSAGES.ERROR_DELETE_ENTRY', 'error');
                }
            )
        }
    }

    public lastSide() {
        return Math.round(this.firmData.count / this.firmData.pageSize) === this.firmData.page;
    }

    public searchFirm(event: any, page: number, pageSize: number, newSearch: boolean = false, clubId: number) {
        const timeStamp = new Date().getTime();
        if (!this.lastSearchTimeStamp || timeStamp - this.lastSearchTimeStamp > 500) {
            this.lastSearchTimeStamp = timeStamp;
            if (clubId && clubId !== null) {
                this.loadFirm = true;
                this.currentClubId = clubId;
                this.firmData = undefined;
                this.searchTerm = event;
                this.search = false;
                this.adminService.getFirmData(encodeURI(this.searchTerm), clubId, page, pageSize).subscribe(
                    (firmData) => {
                        if (!this.userService.enableInfiniteScroll || !this.firmData) {
                            this.firmData = firmData;
                        } else if (firmData.page > this.firmData.page) {
                            for (const data of firmData.firmList) {
                                this.firmData.firmList.push(data);
                            }
                            this.firmData.page = firmData.page;
                        }
                        this.search = true;
                        this.infinityLoading = false;
                        this.loadFirm = false;
                    },
                    (error) => {
                        this.infinityLoading = false;
                        this.loadFirm = false;
                    });
            }
        }
    }

    public navigate(firm: IAdminFirm) {
        this.router.navigate(['admin', firm.firmId]);
    }

}
