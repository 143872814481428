import {Component, ElementRef, OnInit, ViewChild, Input} from '@angular/core';
import {CompanyAdminService} from '../../services/company-admin.service';
import {UserService} from '../../../../shared/services/user/user.service';
import {AdminService} from '../../../club/services/admin.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ErrorHandlerService} from '../../../../shared/components/error-handler/services/error-handler.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {HelperService} from '../../../../shared/services/helper.service';
import {Location} from '@angular/common';
import {CartService} from '../../../../cart/services/cart.service';
import {ConfigService} from '../../../../shared/services/config.service';


@Component({
    selector: 'app-logo',
    templateUrl: './logo.component.html'
})
export class LogoComponent implements OnInit {

    private role: string;
    public firmData;
    private firmID: any;
    private isAdmin: boolean;
    private create: boolean;
    public roles: string[];
    public date: Date;
    public form: UntypedFormGroup;
    public loading = false;
    public maxLength = 120;

    public imgUrl: any;

    @Input('src') public imageSource: string;
    @ViewChild('fileInput', {static: true}) public fileInput: ElementRef;
    @ViewChild('canvas', {static: true}) public canvasElement: ElementRef;
    constructor(public companyAdminService: CompanyAdminService,
                private route: ActivatedRoute,
                public userService: UserService,
                private adminService: AdminService,
                private router: Router,
                private fb: UntypedFormBuilder,
                public errorService: ErrorHandlerService,
                public helperService: HelperService,
                public cartService: CartService,
                private location: Location,
                public configService: ConfigService) {
        this.createForm();
        this.firmData = [];
    }

    ngOnInit() {
        this.errorService.newError = false;
        this.route.params.subscribe(
            (params) => this.firmID = params['id']
        );

        if (!this.location.path().endsWith('logo')) {
            this.firmID = parseInt(this.location.path().substring(this.location.path().lastIndexOf('/') + 1));
        }
        this.getFirm();
        this.configService.getClubConfig();
    }


    private createForm() {
        this.form = this.fb.group({
            name: ['', Validators.required],
            avatar: null
        });
    }

    public onFileChange(event) {
        this.imgUrl = '';

        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            const fileTypes = ['jpg', 'jpeg', 'png'];
            const maxSizeInBytes = 1.5 * 1024 * 1024; // 1,5 MB
            const extension = file.name.split('.').pop().toLowerCase();
            const isSuccess = fileTypes.indexOf(extension) > -1;

            if (isSuccess) {
                if (file.size <= maxSizeInBytes) {
                    this.companyAdminService.hasLogoChanges = true;
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = (e) => {
                        this.form.get('avatar').setValue({
                            filename: file.name,
                            filetype: file.type,
                            value: reader.result.toString().split(',')[1]
                        });
                        if (this.firmData && reader.result) {
                            const logo = this.getFirmLogo();
                            if (logo) {
                                this.imgUrl = reader.result;
                            }
                        }
                    };
                } else {
                    this.helperService.showNotification('TOAST_MESSAGES.ERROR_FILE_TOO_LARGE', 'error');
                }
            } else {
                this.helperService.showNotification('TOAST_MESSAGES.ERROR_INVALID_FILETYPE', 'error');
            }
        }
    }


    public getFootNote() {
        if (this.firmData && this.firmData['FOOTNOTE']) {
            return this.firmData['FOOTNOTE'];
        }
    }

    public setFootNote(newFootNote: string) {
        this.companyAdminService.hasLogoChanges = true;
        if (this.getFootNote()) {
            this.getFootNote().value = newFootNote;
        } else if (this.firmData) {
            this.firmData['FOOTNOTE'] = {key: 'FOOTNOTE', value: newFootNote};
        }
    }

    private getFirm() {
        if (this.firmID > 0 || this.firmID === 'create') {
            this.role = ' - Admin';
            this.isAdmin = true;
            this.adminService.getRolesRest().subscribe((roles: string[]) => this.roles = roles);

            this.create = false;
            this.companyAdminService.getFirmDataById(this.firmID).subscribe(
                (response) => {
                    this.firmData = response.settings;
                });
        } else {
            this.isAdmin = false;
            this.companyAdminService.getFirmSettings().subscribe(
                (response: any) => {
                    this.firmData = response;
                });
        }
    }

    public getFirmLogo() {
        if (this.firmData && this.firmData['FIRM_LOGO']) {
            return this.firmData['FIRM_LOGO'];
        }
    }

    public saveFirm() {
        this.loading = true;
        if (this.isAdmin) {
            this.adminService.saveFirmSettings(this.firmID, this.firmData).subscribe(
                (success) => {
                    this.companyAdminService.hasLogoChanges = false;
                    this.loading = false;
                    this.getFirm();
                    this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_UPDATE', 'success');
                },
                (error) => {
                    this.companyAdminService.hasLogoChanges = false;
                    this.loading = false;
                    this.helperService.showNotification('TOAST_MESSAGES.ERROR_UPDATE', 'error');
                }
            );
        } else {
            if (this.companyAdminService.hasLogoChanges) {
                this.firmData['FIRM_LOGO'].value = this.companyAdminService.currentLogo;
            }

            this.companyAdminService.updateSettings(this.firmData).subscribe(
                (success) => {
                    this.companyAdminService.hasLogoChanges = false;
                    this.loading = false;
                    this.getFirm();
                    this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_UPDATE', 'success');
                    const me = this;
                    setTimeout(function() {
                        me.router.navigate(['company-admin/settings']);
                    }, 3000);
                },
                (error) => {
                    this.companyAdminService.hasLogoChanges = false;
                    this.loading = false;
                    this.helperService.showNotification('TOAST_MESSAGES.ERROR_UPDATE', 'error');
                }
            );
        }
    }
}
