import {Injectable} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {CarService} from './car/car.service';
import {UserService} from './user/user.service';
import {CustomerDataService} from './customerData.service';
import {DmsCarService} from './dms-car.service';
import {HelperService} from './helper.service';

@Injectable()
export class DmsDataService {
    public dmsData: any;

    constructor(
        private cookieService: CookieService,
        private carService: CarService,
        private userService: UserService,
        private customerDataService: CustomerDataService,
        private helperService: HelperService,
        private dmsCarService: DmsCarService
    ) {
        this.userService.accountData$.subscribe((step) => {
            if (step) {
                this.getDmsCookie();
            }
        });
    }

    private checkDmsData() {
        if (this.dmsData) {
            if (!this.isCatalogMode()) {
                this.carService.dmsNoCatalog = true;
                this.carService.setCarExternal$.emit(this.dmsData);
                this.carService.preloadByDMS(this.dmsData);
                this.carService.fromDMS = true;
            }
            if (this.dmsData.hsn && this.dmsData.tsn) {
                this.customerDataService.setHsn(this.dmsData.hsn);
                this.customerDataService.setTsn(this.dmsData.tsn);
            }
            if (this.dmsData.typenumber) {
                this.customerDataService.setTypenumber(this.dmsData.typenumber);
            }
            if (this.dmsData.natcode) {
                this.customerDataService.setNatcode(this.dmsData.natcode);
            }
            if (this.dmsData.vin) {
                this.customerDataService.setVin(this.dmsData.vin);
            }
            if (this.dmsData.kTypeId) {
                this.customerDataService.setKTypeId(this.dmsData.kTypeId);
            }
        }
        this.dmsCarService.getCars(this.dmsData);
    }

    public getDmsCookie() {
        const unparsedDmsData = this.cookieService.get('dmsdata');
        if (unparsedDmsData) {
            try {
                this.dmsData = JSON.parse(atob(unparsedDmsData));
                this.checkDmsData();
            } catch (e) {
                console.log('ERROR while parse cookie to JSON', e);
                console.log('cookie', unparsedDmsData);
                this.helperService.showNotification('TOAST_MESSAGES.ERROR_LOAD_DMS', 'error');
            }

        }

        this.cookieService.delete('dmsdata');
    }

    public isCatalogMode() {
        return (this.userService.getCatalogMode() || (this.dmsData && this.dmsData.catalogMode));
    }
}
