<div class="card car-selection">
    <div class="logo {{userService?.accountData?.language}} {{cartService.getCalculation()}} {{userService?.accountData?.country}}"></div>
    <div class="alert alert-danger text-center" *ngIf="noCarSelected">
        <b>{{ 'CAR_SELECTION.PLEASE_SELECT_CAR' | translate }}</b>
        <br/>
        <i>{{ 'CAR_SELECTION.WARNING_SELECT_CAR' | translate }}</i>
    </div>
    <div class="alert alert-info text-center" *ngIf="noMaintenance && !noCarSelected">
        <b>{{'CAR_SELECTION.NO_MAINTENANCE' | translate}}</b>
        <br/>
        <i>{{'CAR_SELECTION.CHECK_MAITENANCE' | translate}}</i>
    </div>

    <div class="alert alert-danger text-center"
         *ngIf="dmsDataService.isCatalogMode() && !carService.selectedCar && carService.noCarData">
        <b>{{'TOAST_MESSAGES.ERROR_NO_CAR_FOUND' | translate}}</b>
    </div>

    <div class="alert alert-info text-center"
         *ngIf="dmsDataService.isCatalogMode() && !carService.selectedCar && !carService.canEject">
        <b>{{'CAR_SELECTION.CATALOG_MODE' | translate}}</b>
    </div>


    <div class="car-selection"
         *ngIf="!showCarSelectionModal && !showCarSelectionModalVIN && !dmsDataService.isCatalogMode()">
        <div class="search-types"
             *ngIf="carService.dmsNoCatalog && carService.fromDMS
             && ((configService.getActiveForNameByMode('VIN_SEARCH', 'VIN', 'DMS') && carService.showPreSelection)
             || (configService.getActiveForNameByMode('CAR_SELECTION', 'NATCODE', 'DMS') && carService.dmsNatCodeCar?.length > 0)
             || (configService.getActiveForNameByMode('CAR_SELECTION', 'KTYPE', 'DMS') && carService.dmsKTypeCar?.length > 0))"
             style="border: none">
            <app-preload-car-selection></app-preload-car-selection>
        </div>
        <ng-container *ngFor="let type of configService.getCarSelectionSettingForMode(getMode())">
            <div class="search-types" [ngSwitch]="type.name" *ngIf="type.active">
                <app-car-by-kba
                        *ngSwitchCase="'KBA'"
                        [hsn]="dataService.getHsn()"
                        [tsn]="dataService.getTsn()"
                        (applyCars)="applyCars($event)">
                </app-car-by-kba>

                <app-car-by-license-swiss
                        *ngSwitchCase="'SWISS_TYPENO'"
                        [numberplate]="dataService.getTypenumber()"
                        (applyCars)="applyCars($event)">
                </app-car-by-license-swiss>

                <app-car-by-model
                        *ngSwitchCase="'MANUALLY'"
                        (applyCars)="applyCars($event)">
                </app-car-by-model>

                <app-car-by-natcode
                        *ngSwitchCase="'NATCODE'"
                        (applyCars)="applyCars($event)">
                </app-car-by-natcode>

                <app-vin-search-wrapper
                        *ngSwitchCase="'VIN'"
                        (applyCars)="applyCars($event)"
                        (selectVinCar)="selectVinCar($event)">
                </app-vin-search-wrapper>

                <app-car-by-motorcode
                        *ngSwitchCase="'MOTORCODE'"
                        (applyCars)="applyCars($event)">
                </app-car-by-motorcode>

                <app-car-by-ktypeid
                        *ngSwitchCase="'KTYPE'"
                        [kTypeId]="dataService.getKTypeId()"
                        (applyCars)="applyCars($event)">
                </app-car-by-ktypeid>

                <app-numberplate-search-wrapper
                        *ngSwitchCase="'NUMBERPLATE'"
                        (applyCars)="applyCars($event)"
                        [numberplate]="dataService.getNumberplate()">
                </app-numberplate-search-wrapper>
            </div>
        </ng-container>
        <ng-container *ngIf="!(configService.getSettingByName('CAR_SELECTION'))">
            <app-default-loading-page></app-default-loading-page>
        </ng-container>

    </div>


    <!-- DMS mode -->
    <div class="car-selection"
         *ngIf="!showCarSelectionModal && !showCarSelectionModalVIN && dmsDataService.isCatalogMode()">

        <ng-container *ngIf="!(configService.getSettingByName('CAR_SELECTION'))">
            <app-default-loading-page></app-default-loading-page>
        </ng-container>

        <app-dms-car-selection (applyCars)="applyCars($event)" *ngIf="carService.canEject"></app-dms-car-selection>
    </div>

    <div class="row">
        <div class="col-3"></div>
        <div class="col-9">
            <div class="row">
                <div class="col-9">
                    <div class="cooperation">
                        <div class="cooperation-image"></div>
                        <div class="cooperation-text"
                             *ngIf="themeService?.currentTheme">{{'COOPERATION.' + themeService?.currentTheme | translate}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="car-selection-model-selection" *ngIf="showCarSelectionModal && !carService.modalHidden">
        <label class="search-label">{{ 'CAR_SELECTION.PROMPT.MESSAGE' | translate}}</label>

        <div class="alert alert-info text-center" *ngIf="carList && carList.length > 0 && carList[0]?.fromVIN">
            <i class="fa fa-info-circle mr-2"></i>
            <span>{{'CAR_SELECTION.NO_AUTOVISTA' | translate}}</span>
        </div>

        <div class="container">
            <div class="row" *ngFor="let car of carList" (click)="applyCar(car)">
                <div class="col">
                    <i class="fa fa-car"></i>
                    {{car?.displayName}} {{car?.engineCodes?.length > 0 ? ' (' + car?.engineCodes?.toString() + ')' : ''}}
                </div>
            </div>

            <div class="row button-row">
                <div class="col">
                    <button class="base-btn model-button-back"
                            *ngIf="!carService.fromDMS && !carService.showPreSelection" (click)="resetModal()">
                        {{'GENERAL.BACK' | translate}}
                    </button>
                    <button class="base-btn model-button-back" *ngIf="carService.showPreSelection"
                            (click)="hideModal()">
                        {{'GENERAL.ABORT' | translate}}
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="car-selection-model-selection" *ngIf="showCarSelectionModalVIN">
        <label class="search-label">{{ 'CAR_SELECTION.PROMPT.MESSAGE' | translate}}</label>

        <div class="alert alert-info text-center"
             *ngIf="vinCarWrapper.errorCode == -1 || vinCarWrapper.errorCode == -3 || vinCarWrapper.errorCode == -4">
            <i class="fa fa-info-circle mr-2"></i>
            <span>{{'CAR_SELECTION.NO_AUTOVISTA' | translate}}</span>
        </div>

        <div class="container">
            <div class="row" *ngFor="let car of vinCarWrapper.vinCar" (click)="applyVinCarSelection(car)">
                <div class="col">
                    <i class="fa fa-car"></i>
                    <span *ngIf="car.makeDescription">{{car?.makeDescription}}</span>
                    <span *ngIf="car.typeDescription">{{car?.typeDescription}}</span>
                    <span *ngIf="car.name">{{car?.name}}</span>
                </div>
            </div>
        </div>

        <button class="base-btn model-button-back" (click)="resetModal()">
            {{'GENERAL.BACK' | translate}}
        </button>
    </div>
</div>


