import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AdminService} from '../../../services/admin.service';
import {UserService} from '../../../../../shared/services/user/user.service';

@Component({
    selector: 'app-edit-firm-user',
    templateUrl: './edit-firm-user.component.html'
})
export class EditFirmUserComponent implements OnInit, OnDestroy {

    public userId = 0;
    public userData: any = {
        active: true,
        userName: '',
        displayName: '',
        roles: [],
        email: '',
        firmId: 0,
        country: '',
        id: 0
    };

    constructor(private router: Router,
                public userService: UserService,
                private route: ActivatedRoute,
                public adminService: AdminService) {
    }

    ngOnInit() {
        this.adminService.newUserData$.subscribe(user => {
            if (user) {
                this.userData = user;
            }
        })

        this.route.params.subscribe(
            (params) => {
                if (params['userId'] > 0) {
                    this.userId = 0 + +params['userId'];
                }
                if (!this.adminService.selectedFirm || this.adminService.selectedFirm.firmId !== params['id']) {
                    this.adminService.getFirmDataById(params['id']).subscribe(
                        (firm) => {
                            this.adminService.selectedFirm = firm;
                            this.selectUser();
                        }
                    )
                } else {
                    this.selectUser();
                }
            }
        );
    }

    ngOnDestroy() {
        this.adminService.newUserData$.next(undefined);
    }

    public selectUser() {
        if (this.userId > 0) {
            for (const login of this.adminService.selectedFirm.loginList) {
                if (login.id === this.userId) {
                    this.userData = login;
                }
            }
        }
    }

    public reset() {
        this.adminService.getFirmDataById(this.adminService.selectedFirm.firmId);
        this.router.navigate(['admin/firm', this.adminService.selectedFirm.firmId]);
    }

    public update() {
        this.userData.country = this.adminService.selectedFirm.address.country.iso31661Alpha2;
        if (this.userData.id === 0) {
            this.userData.firmId = this.adminService.selectedFirm.firmId;
            this.adminService.createUser(this.userData);

        } else {
            this.userData.valid = true;
            this.adminService.setUserData(this.userData, this.userId);
        }
    }

    public updateReset() {
        this.update();
        this.reset();
    }

}
