import {Component, EventEmitter, OnInit} from '@angular/core';
import {CartService} from "../../cart/services/cart.service";
import {UserService} from "../../shared/services/user/user.service";
import {environment} from "../../../environments/environment";
import {ActivatedRoute } from "@angular/router";
import {Location} from "@angular/common";
import {MaintenanceService} from "../../maintenance/services/maintenance.service";
import {WorkService} from "../../work-data/services/work.service";
import {ConfigService} from '../../shared/services/config.service';
import {CarService} from '../../shared/services/car/car.service';
import {ThemeService} from '../../shared/services/theme.service';
import {PartnerService} from '../../shared/services/partner/partner.service';
import {AdminService} from '../../admin/club/services/admin.service';
import {CompanyAdminService} from '../../admin/company/services/company-admin.service';

@Component({
    selector: 'app-header',
    templateUrl: 'header.component.html'
})
export class HeaderComponent implements OnInit {
    public imageUrlPrefix: string;
    public burgerOpen: boolean = false;
    public firmSettings: any;

    constructor(
        public cartService: CartService,
        public carService: CarService,
        public userService: UserService,
        public maintenanceService: MaintenanceService,
        public workService: WorkService,
        public route: ActivatedRoute,
        public location: Location,
        public configService: ConfigService,
        public themeService: ThemeService,
        public partnerService: PartnerService,
        public companyAdminService: CompanyAdminService
    ) {
        this.imageUrlPrefix = environment.baseUrl;
    }

    ngOnInit() {
        this.companyAdminService.firmSettingEmitter.subscribe((response) => {
            this.firmSettings = response;
            if (this.firmSettings && this.firmSettings.START_IN_SALE_MODE.value === 'true') {
                this.userService.sale = true;
            }
        });
    }

}
